import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import bg1 from '../assets/images/hero/bg.jpg';
import Navbar from "../components/navbar";
import AboutTwo from "../components/aboutTwo";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { FiClock, FiBookmark } from "../assets/icons/vander";
import StatusBadge from "../data/StatusBadge";
import getAllBookmarks from "../common/realBackend/bookmark/GetAllBookmarks";
import deleteBookmark from "../common/realBackend/bookmark/DeleteBookmark";
import hero1 from '../assets/images/hero1.png';

export default function JobListOne() {
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        const fetchBookmarkedCandidates = async () => {
            try {
                const response = await getAllBookmarks();
                console.log('Fetched bookmarked candidates data:', response);
                if (response.status === 'success' && Array.isArray(response.data.bookmarks)) {
                    setCandidates(response.data.bookmarks);
                } else {
                    console.error('Fetched data is not an array:', response);
                    setCandidates([]);
                }
            } catch (error) {
                console.error('Error fetching bookmarked candidates:', error);
                setCandidates([]);
            }
        };

        fetchBookmarkedCandidates();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteBookmark(id);
            setCandidates(candidates.filter(candidate => candidate.id !== id));
            console.log('Bookmark deleted successfully');
        } catch (error) {
            console.error('Error deleting bookmark:', error);
        }
    };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />

            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Bookmarked Candidates</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Candidates</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-8 col-md-10 col-12 mx-auto">
                            <div className="row g-4">
                                {Array.isArray(candidates) && candidates.length > 0 ? candidates.map((bookmark) => (
                                    <div className="col-12" key={bookmark.id}>
                                        <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                            <div className="d-flex align-items-center w-250px">
                                                {/* Check if bookmark.Candidate exists and has a profileImage */}
                                                {bookmark.Candidate && (
                                                    <>
                                                        <img
                                                            src={bookmark.Candidate.profileImage || hero1}
                                                            className="avatar avatar-md-md rounded-pill shadow-md"
                                                            alt={bookmark.Candidate.fullName}
                                                        />
                                                        <div className="ms-3">
                                                            <Link to={`/candidate-profile/${bookmark.CandidateId}`} className="h5 title text-dark">
                                                                {bookmark.Candidate.fullName}
                                                            </Link>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                                <span className="">Bookmarked At</span>
                                                <span className="text-muted d-flex align-items-center fw-medium mt-md-2">
                                                    <FiClock className="fea icon-sm me-1 align-middle" />
                                                    {new Date(bookmark.createdAt).toLocaleDateString()}
                                                </span>
                                            </div>
                                            <button
                                                className="btn btn-sm btn-danger w-full ms-md-1"
                                                onClick={() => handleDelete(bookmark.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                )) : (
                                    <div className="col-12">
                                        <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                            <div className="d-flex align-items-center w-250px">
                                                <div className="ms-3">
                                                    <h5 className="title text-dark">No candidates found.</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer top={true} />
            <ScrollTop />
        </>
    );
}
