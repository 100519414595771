import api from '../../../helpers/api_helper';

const getAllSubscriptionPlans = async () => {
  try {
    const response = await api.get('/api/v1/subscriptionPlans/getAllSubscriptionPlans');
    console.log('Subscription plans fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription plans:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getAllSubscriptionPlans;
