import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBroom, FaBaby, FaCar, FaCocktail, FaHome, FaUser, FaCloudsmith } from "react-icons/fa";
import { FiMessageCircle } from "react-icons/fi";

import bg1 from "../assets/images/hero/bg.jpg";

import Navbar from "../components/navbar";
import Faq from "../components/faq";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import getAllDomesticCategories from "../common/realBackend/domesticCategory/GetAllDomesticCategories";

export default function JobCategories() {
    const [categoriesData, setCategoriesData] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getAllDomesticCategories();
                console.log("API Response:", response); // Debugging line

                if (Array.isArray(response)) {
                    setCategoriesData(response);
                    console.log('Categories state set:', response);
                } else {
                    console.warn("No categories found in response"); // Debugging line
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    const getCategoryIcon = (categoryName) => {
        switch (categoryName.toLowerCase()) {
            case 'senior housekeeper':
                return <FaBroom />;
            case 'nanny':
                return <FaBaby />;
            case 'driver':
                return <FaCar />;
            case 'cook':
                return <FaCocktail />;
            case 'butler':
                return <FaHome />;
            case 'personal assistant':
                return <FaUser />;
            case 'security personnel':
                return <FaUser />;
            case 'laundrer':
                return <FaCloudsmith />;
            case 'chef':
                // Add an icon for Chef (using a placeholder for now)
                return <FiMessageCircle />;
            case 'gardener':
                // Add an icon for Gardener (using a placeholder for now)
                return <FiMessageCircle />;
            default:
                return <FiMessageCircle />;
        }
    };
    

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Job Categories</h5>
                            </div>
                        </div>
                    </div>
                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Categories</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row row-cols-lg-5 row-cols-md-4 row-cols-sm-2 row-cols-1 g-4">
                        {categoriesData.length > 0 ? (
                            categoriesData.map((item) => (
                                <div className="col" key={item.id}>
                                    <Link to={`/candidates?category=${encodeURIComponent(item.name)}`} className="text-decoration-none">
                                        <div
                                            className="position-relative job-category text-center px-4 py-5 rounded shadow"
                                            style={{ height: '260px' }}
                                        >
                                            <div className="feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center" style={{ width: '50px', height: '50px' }}>
                                                {getCategoryIcon(item.name)}
                                            </div>
                                            <div className="mt-4">
                                                <h5 className="title h5 text-dark">{item.name}</h5>
                                                <p className="text-muted mb-0 mt-3">{item.candidateCount} Domestic Workers Available</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 text-center">
                                <p>No categories available.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <Faq />
                </div>
            </section>
            <Footer top={true} />
            <ScrollTop />
        </>
    );
}
