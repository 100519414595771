import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Modal, Button, Form } from 'react-bootstrap'

import bg1 from '../assets/images/hero/bg.jpg';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { blacklistData } from "../data/blacklist";
import createCandidate from '../common/realBackend/candidates/CreateCandidate';
import getAllCandidates from '../common/realBackend/candidates/GetAllCandidates';
import blacklistCandidate from "../common/realBackend/candidates/BlacklistCandidate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function UploadCandidateToBlacklist() {
    const [areaCodes, setAreaCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const searchInputRef = useRef(null);
    const resultsListRef = useRef(null);

    useEffect(() => {
        const fetchAreaCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const data = response.data.map(country => ({
                    code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
                    flag: country.flags.svg,
                    name: country.name.common
                }));
                const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
                setAreaCodes(sortedData);
            } catch (error) {
                console.error('Error fetching area codes:', error);
            }
        };
        fetchAreaCodes();
    }, []);

    const handleAreaCodeChange = (event) => {
        setSelectedCode(event.target.value);
    };

    const MinMaxInput = () => {
        useEffect(() => {
            const minInput = document.getElementById('Min');
            const maxInput = document.getElementById('Max');
    
            function validateMinMax() {
                const minValue = parseInt(minInput.value, 10);
                const maxValue = parseInt(maxInput.value, 10);
    
                if (minValue && maxValue) {
                    if (minValue >= maxValue) {
                        maxInput.setCustomValidity('Max must be greater than Min');
                    } else {
                        maxInput.setCustomValidity('');
                    }
                } else {
                    maxInput.setCustomValidity('');
                }
            }
    
            minInput.addEventListener('input', validateMinMax);
            maxInput.addEventListener('input', validateMinMax);
    
            return () => {
                minInput.removeEventListener('input', validateMinMax);
                maxInput.removeEventListener('input', validateMinMax);
            };
        }, []);
    }

    const [showReport, setShowReport] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [exactMatchMessage, setExactMatchMessage] = useState("");
    const [candidates, setCandidates] = useState([]);
    const [candidateId, setCandidateId] = useState(null)
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [skills, setSkills] = useState([]);
    const [skillName, setSkillName] = useState('');
    const [skillPercentage, setSkillPercentage] = useState('');

    const handleAddSkill = () => {
        if (skillName && skillPercentage >= 0 && skillPercentage <= 100) {
            setSkills([...skills, { name: skillName, percentage: skillPercentage }]);
            setSkillName('');
            setSkillPercentage('');
        } else {
            alert('Please enter a valid skill and percentage between 0 and 100.');
        }
    };

    const handleSkillNameChange = (e) => setSkillName(e.target.value);
    const handleSkillPercentageChange = (e) => setSkillPercentage(e.target.value);


    const toggleReport = () => {
        setShowReport(!showReport);
    };

     // Fetch candidates from API using getAllCandidates
     useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await getAllCandidates();
                if (Array.isArray(response.data.candidates)) {
                    const blacklistedCandidates = response.data.candidates.filter(candidate => candidate.blacklistCount > 0);
                    const names = blacklistedCandidates.map(candidate => 
                        `${candidate.fullName || ""} (${candidate.domesticCategory || ""})`); // Default to empty string if fullName is undefined
                    setCandidates(names);
                    
                    console.log('Blacklisted candidates:', blacklistedCandidates);
                    console.log('Extracted names:', names); // Log extracted names
                } else {
                    console.error('Expected response.data.candidates to be an array, but got:', response.data.candidates);
                }
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };
    
        fetchCandidates();
    }, []);
    
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
    
        // Filter the candidates based on the input value
        const filteredResults = candidates.filter(name =>
            typeof name === 'string' && name.toLowerCase().includes(value.toLowerCase())
        );
    
        setSearchResults(filteredResults);
    };

    const handleSelectName = (name) => {
        setSearchValue(name);
        setSearchResults([]);
    
        // Check if the selected name is an exact match
        const exactMatch = candidates.find(candidate =>
            candidate.toLowerCase() === name.toLowerCase()
        );
    
        if (exactMatch) {
            setExactMatchMessage(`This person is already blacklisted. View Blacklist for more details.`);
        } else {
            setExactMatchMessage("");
        }
    };

    const handleKeyDown = (e) => {
        // Handle Enter key press
        if (e.key === 'Enter') {
            if (searchResults.length > 0) {
                handleSelectName(searchResults[0].name); // Select the first item in the list
            }
        }
    };

    const handleClickOutside = (event) => {
        if (resultsListRef.current && !resultsListRef.current.contains(event.target) && !searchInputRef.current.contains(event.target)) {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        console.log('Submit function triggered');
    
        // Extract values from the form
        const name = document.getElementById('name').value;
        const about = document.getElementById('about').value;
        const mobile = document.getElementById('mobile').value;
        const jobCategory = document.getElementById('Categories').value;
        const profileImage = document.getElementById('formFile').files[0];
        const bgprofileImage = document.getElementById('formFile1').files[0];
        const cv = document.getElementById('formFile2').files[0];
        const lga = document.getElementById('LGA').value;
        const min_sal = document.getElementById('Min').value;
        const max_sal = document.getElementById('Max').value;
        const email = document.getElementById('email').value;
        const mobile_area = document.getElementById('areaCode').value;
        const whatsapp_area = document.getElementById('areaCode1').value;
        const whatsapp = document.getElementById('whatsapp').value;
        const language = document.getElementById('language').value;
        const experience = document.getElementById('experience').value;
        const qualifications = document.getElementById('qualifications').value;
        const expEndYear = document.getElementById('expEndYear').value;
        const company = document.getElementById('company').value;
        const jobtitle = document.getElementById('jobTitle').value;
        const endYear = document.getElementById('endYear').value;
        const startYear = document.getElementById('startYear').value;
        const institution = document.getElementById('institution').value;
        const degree = document.getElementById('degree').value;
        const expStartYear = document.getElementById('expStartYear').value;
    
        // Ensure compulsory fields are filled
        if (!name || !about || !mobile || !jobCategory || !lga || !mobile_area) {
            alert('Please fill in all the compulsory fields.');
            return;
        }
    
        // Construct the raw data object
        const candidateData = {
            firstName: name.split(' ')[0],
            lastName: name.split(' ').slice(1).join(' '),
            about: about,
            phone: `${mobile_area}${mobile}`,
            whatsapp: `${whatsapp_area}${whatsapp}`,
            domesticCategory: jobCategory,
            yearsOfExperience: parseInt(experience, 10),
            location: lga,
            resume: cv, // Handle file uploads as needed
            education: [
                {
                    degree,
                    institution,
                    startYear: startYear ? parseInt(startYear, 10) : null,
                    endYear: endYear ? parseInt(endYear, 10) : null
                }
            ],
            experience: [
                {
                    jobTitle: jobtitle,
                    company,
                    startYear: expStartYear ? parseInt(expStartYear, 10) : null,
                    endYear: expEndYear ? parseInt(expEndYear, 10) : null
                }
            ],
            profileImage: profileImage, // Handle file uploads as needed
            bgprofileImage: bgprofileImage // Handle file uploads as needed
        };
    
        const jsonData = JSON.stringify(candidateData);
    
        console.log('Raw JSON data:', jsonData);
    
        try {
            // Create the candidate
            const createResponse = await createCandidate(jsonData);
            console.log('Candidate created successfully:', createResponse);
    
            // Store the candidate ID for reporting later
            setCandidateId(createResponse.id); // Update this function call according to your state management
        } catch (error) {
            console.error('Error in candidate creation:', error);
        }
    };

    const handleReportSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        console.log('Report submit function triggered');
    
        // Extract the comment from the modal
        const comment = document.getElementById('comment').value;
    
        // Ensure the comment is not empty
        if (!comment.trim()) {
            alert('Please enter a comment.');
            return;
        }
    
        try {
            // Blacklist the candidate
            const blacklistResponse = await blacklistCandidate({
                candidateId, // Pass the candidate ID stored from handleSubmit
                comment
            });
    
            console.log('Candidate blacklisted successfully:', blacklistResponse);
    
            // Notify the user of success
            setSuccessMessage('Candidate has been blacklisted successfully!');
            setErrorMessage('');
            toast.success('Candidate has been blacklisted successfully!');
        } catch (error) {
            console.error('Error in blacklisting candidate:', error);
            setErrorMessage('Error blacklisting candidate. Please try again.');
            setSuccessMessage('');
            toast.error('Error blacklisting candidate. Please try again.');
        }
    };
        
    

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />

            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Create a Domestic Worker </h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                                <li className="breadcrumb-item"><Link to="/job-grid-one">Candidates</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create a Domestic Worker</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8">
                            <div className="card border-0">
                                <form className="rounded shadow p-4" onSubmit = {handleSubmit}>
                                    <div className="row">
                                        <h5 className="mb-3">Profile Overview:</h5>
                                        <div className="alert alert-danger mt-2">
                                        <div className="d-flex align-items-start">
                                            <i className="fa fa-exclamation-triangle fa-fw me-2"></i>
                                            <div>
                                                <p className="mb-3">
                                                    When you add a worker's information to Precheck Hire, it becomes ours to use for running and improving our service.
                                                </p>
                                                <p className="mb-0">
                                                    Precheck Hire isn't involved in what happens between you and a domestic worker you find here. We connect you, but the rest is up to you both.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFile" className="form-label fw-semibold">Profile Picture (Compulsory) :</label>
                                                <input className="form-control" type="file" id="formFile" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFile" className="form-label fw-semibold">Profile Cover Image :</label>
                                                <input className="form-control" type="file" id="formFile" />
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ position: 'relative' }}>
                                        <div className="col-12" style={{ position: 'relative' }}>
                                            <div className="mb-3" style={{ position: 'relative' }}>
                                                <label className="form-label fw-semibold">Name :</label>
                                                <input
                                                name="name"
                                                id="name"
                                                className="form-control"
                                                placeholder="Firstname Middlename Lastname"
                                                value={searchValue}
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                ref={searchInputRef}
                                                required
                                            />
                                            {searchResults.length > 0 && (
                                                <ul ref={resultsListRef} className="list-group" style={{
                                                    position: 'absolute',
                                                    zIndex: 1000,
                                                    width: '100%',
                                                    maxHeight: '150px',
                                                    overflowY: 'auto',
                                                    backgroundColor: 'white',
                                                    border: '1px solid #ccc',
                                                    borderTop: 'none',
                                                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                                    marginTop: '1px'
                                                }}>
                                                    {searchResults.map((name, index) => (
                                                        <li
                                                            key={index}
                                                            className="list-group-item"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleSelectName(name)}
                                                        >
                                                            {name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {exactMatchMessage && (
                                                <div className="alert alert-danger mt-2">
                                                    {exactMatchMessage}
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">About Domestic Worker :</label>
                                                <textarea name="about" id="about" rows="4" className="form-control" placeholder="Tell us something about the worker " required></textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Job Category:</label>
                                                <select className="form-control form-select" id="Categories" required>
                                                    <option value="Butler">Butler</option>
                                                    <option value="Nanny">Nanny</option>
                                                    <option value="Driver">Driver</option>
                                                    <option value="Chef">Chef</option>
                                                    <option value="House Keeper">House Keeper</option>
                                                </select>
                                                
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-0">
                                                <label className="form-label fw-semibold">Salary:</label>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label className="form-label small fw-bold d-none"></label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text border" id="basic-addon1">₦</span>
                                                        <input type="number" className="form-control" placeholder="Min" id="Min" aria-describedby="inputGroupPrepend" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="mb-3">
                                                    <label className="form-label small fw-bold d-none"></label>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text border" id="basic-addon1">₦</span>
                                                        <input type="number" className="form-control" placeholder="Max" id="Max" aria-describedby="inputGroupPrepend"  />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <script><MinMaxInput></MinMaxInput></script>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFile" className="form-label fw-semibold">Upload Cv / Resume (Optional):</label>
                                                <input className="form-control" type="file" id="formFile" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <h5 className="mb-3">Contact:</h5>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Local Government:</label>
                                                <select className="form-control form-select" id="Categories" required>
                                                    <option value="Butler">Alimosho</option>
                                                    <option value="Nanny">Kosofe</option>
                                                    <option value="Driver">Igando</option>
                                                    <option value="Chef">Ajinomoto</option>
                                                    <option value="House Keeper">Ikeja</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Email:</label>
                                                <input name="email" id="email" type="text" className="form-control" placeholder="Email" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Phone Number:</label>
                                                <div className="d-flex">
                                                    <select
                                                        name="areaCode"
                                                        id="areaCode"
                                                        className="form-control me-2"
                                                        onChange={handleAreaCodeChange}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {areaCodes.map((areaCode, index) => (
                                                            <option key={index} value={areaCode.code}>
                                                                <img
                                                                    src={areaCode.flag}
                                                                    alt={areaCode.name}
                                                                    style={{ width: '20px', marginRight: '10px' }}
                                                                />
                                                                {areaCode.name} {areaCode.code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        name="mobile"
                                                        id="mobile"
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Enter other digits"
                                                        pattern="[0-9]*"
                                                        title="Please enter other digits of phone number"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Whatsapp Number:</label>
                                                <div className="d-flex">
                                                    <select
                                                        name="areaCode"
                                                        id="areaCode"
                                                        className="form-control me-2"
                                                        onChange={handleAreaCodeChange}
                                                    >
                                                        <option value="">Select Country</option>
                                                        {areaCodes.map((areaCode, index) => (
                                                            <option key={index} value={areaCode.code}>
                                                                <img
                                                                    src={areaCode.flag}
                                                                    alt={areaCode.name}
                                                                    style={{ width: '20px', marginRight: '10px' }}
                                                                />
                                                                {areaCode.name} {areaCode.code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        name="whatsapp"
                                                        id="whatsapp"
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Enter other digits"
                                                        pattern="[0-9]*"
                                                        title="Please enter other digits of phone number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <h5 className="mb-3">Education (Optional):</h5>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Degree:</label>
                                                <input name="degree" id="degree" className="form-control" placeholder="Bachelor's Degree" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Institution:</label>
                                                <input name="institution" id="institution" className="form-control" placeholder="University of XYZ" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Start Year:</label>
                                                <input name="startYear" id="startYear" type="number" className="form-control" placeholder="2010" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">End Year:</label>
                                                <input name="endYear" id="endYear" type="number" className="form-control" placeholder="2014" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <h5 className="mb-3">Experience (Optional):</h5>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Job Title:</label>
                                                <input name="jobTitle" id="jobTitle" className="form-control" placeholder="Nanny" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Company:</label>
                                                <input name="company" id="company" className="form-control" placeholder="ABC Family" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Start Year:</label>
                                                <input name="expStartYear" id="expStartYear" type="number" className="form-control" placeholder="2015" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">End Year:</label>
                                                <input name="expEndYear" id="expEndYear" type="number" className="form-control" placeholder="2020" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <h5 className="mb-3">Skill & Experience:</h5>
                                        <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">Skills:</label>
                                                    <div className="d-flex">
                                                        <Form.Control
                                                            type="text"
                                                            value={skillName}
                                                            onChange={handleSkillNameChange}
                                                            placeholder="e.g., Time Management"
                                                        />
                                                        <Form.Control
                                                            type="number"
                                                            value={skillPercentage}
                                                            onChange={handleSkillPercentageChange}
                                                            placeholder="Percentage (0-100)"
                                                            min="0"
                                                            max="100"
                                                            className="ms-2"
                                                        />
                                                        <Button onClick={handleAddSkill} className="btn btn-primary ms-2">
                                                            Add Skill
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>Added Skills:</h6>
                                                    <ul className="list-unstyled">
                                                        {skills.map((skill, index) => (
                                                            <li key={index} className="border-bottom py-1">
                                                                {skill.name} - {skill.percentage}%
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Qualifications:</label>
                                                <input name="qualifications" id="qualifications" type="text" className="form-control" placeholder="e.g., SSCE" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Experience:</label>
                                                <input name="experience" id="experience" type="text" className="form-control" placeholder="e.g., 5 Years" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label fw-semibold">Languages:</label>
                                                <input name="language" id="language" type="text" className="form-control" placeholder="e.g., Yoruba, English" />
                                            </div>
                                        </div>

                                    </div>                                                                                     

                                    <div className="row">
                                        <div className="col d-flex justify-content-end">
                                            <input type="button" id="report" name="report" className="btn btn-danger" value="Report" onClick={handleModalShow}/>
                                        </div>
                                    </div>

                                </form>
                                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showReport} onHide={toggleReport}>
                    <Modal.Header closeButton>
                        <Modal.Title>Report Candidate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleReportSubmit}>
                            <Form.Group controlId="comment">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter your comment" />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit Report
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </section>
            <Footer top={true} />
            <ScrollTop />
            <ToastContainer/>
        </>
    );
    }