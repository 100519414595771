import api from '../../../helpers/api_helper';

const sendMessage = async (candidateId, messageContent) => {
  try {
    const payload = {
      candidateId: candidateId,
      messageContent: messageContent,
    };

    const response = await api.post('/api/v1/notifications/sendMessage', payload);
    console.log('Message sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default sendMessage;
