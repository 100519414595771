import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Base URL
const BASE_URL = 'https://api-stack-api.onrender.com/';

// Function to get token from localStorage
const getTokenFromLocalStorage = () => {
  const authUser = localStorage.getItem("authUser");
  if (authUser) {
    try {
      const parsedAuthUser = JSON.parse(authUser);
      return parsedAuthUser.token || null;
    } catch (error) {
      console.error('Error parsing authUser from localStorage:', error);
      return null;
    }
  }
  return null;
};

const token = getTokenFromLocalStorage();

// Log the token to the console
console.log('Token from localStorage:', token);

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: BASE_URL,
});

// Optional: You can add interceptors for request and response if needed
api.interceptors.request.use(
  config => {
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  error => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    if (error.response && error.response.data.message === "Your token has expired! Please log in again.") {
      // Redirect to login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
