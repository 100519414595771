import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import bg1 from '../assets/images/hero/bg.jpg';
import Navbar from "../components/navbar";
import AboutTwo from "../components/aboutTwo";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { jobData } from "../data/jobData";
import { FiClock, FiMapPin, FiBookmark } from "../assets/icons/vander";
import StatusBadge from "../data/StatusBadge";
import getAllUserCandidates from "../common/realBackend/candidates/GetAllUserCandidates";
import deleteCandidate from "../common/realBackend/candidates/DeleteCandidate";
import getNigeriaState from "../common/realBackend/users/GetNigeriaState";
import getAllDomesticCategories from "../common/realBackend/domesticCategory/GetAllDomesticCategories";
import hero1 from '../assets/images/hero1.png';

export default function JobListTwo() {
    const [candidates, setCandidates] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [states, setStates] = useState([]);
    const [stateFilter, setStateFilter] = useState("All");
    const [categories, setCategories] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState("All");

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await getAllUserCandidates();
                console.log('Fetched candidates data:', response);
                if (response.status === 'success' && Array.isArray(response.data.candidates)) {
                    setCandidates(response.data.candidates);
                } else {
                    console.error('Fetched data is not an array:', response);
                    setCandidates([]);
                }
            } catch (error) {
                console.error('Error fetching candidates:', error);
                setCandidates([]);
            }
        };

        fetchCandidates();
    }, []);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await getNigeriaState();
                console.log('Fetched states data:', response);
                if (response.data && Array.isArray(response.data)) {
                    setStates(response.data);
                } else {
                    console.error('Fetched data is not an array:', response);
                    setStates([]);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
                setStates([]);
            }
        };

        fetchStates();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getAllDomesticCategories();
                console.log('Fetched categories data:', response);
                if (Array.isArray(response)) {
                    setCategories(response);
                } else {
                    console.error('Fetched data is not an array:', response);
                    setCategories([]);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategories([]);
            }
        };

        fetchCategories();
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteCandidate(id);
            setCandidates(candidates.filter(candidate => candidate.id !== id));
            console.log('Candidate deleted successfully');
        } catch (error) {
            console.error('Error deleting candidate:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleStateFilterChange = (event) => {
        setStateFilter(event.target.value);
    };

    const handleCategoryFilterChange = (event) => {
        setCategoryFilter(event.target.value);
    };

    const filteredCandidates = candidates.filter(candidate => {
        return (
            candidate.fullName.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (stateFilter === "All" || candidate.location === stateFilter) &&
            (categoryFilter === "All" || candidate.domesticCategory === categoryFilter)
        );
    });

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Manage Candidate</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Candidate</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="card bg-white p-4 rounded shadow sticky-bar">
                                <div>
                                    <h6 className="mb-0">Search Properties</h6>
                                    <div className="search-bar mt-2">
                                        <div id="itemSearch2" className="menu-search mb-0">
                                            <Form className="searchform">
                                                <Form.Control
                                                    type="text"
                                                    className="form-control rounded border"
                                                    placeholder="Search..."
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="mb-0">Categories</h6>
                                        <select
                                            className="form-select form-control border mt-2"
                                            aria-label="Default select example"
                                            value={categoryFilter}
                                            onChange={handleCategoryFilterChange}
                                        >
                                            <option value="All">All Categories</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.name}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="mb-0">Location</h6>
                                        <select
                                            className="form-select form-control border mt-2"
                                            aria-label="Default select example"
                                            value={stateFilter}
                                            onChange={handleStateFilterChange}
                                        >
                                            <option value="All">All States</option>
                                            {states.map((state) => (
                                                <option key={state} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-6 col-12">
                            <div className="row g-4">
                                {Array.isArray(candidates) && candidates.length > 0 ? filteredCandidates.map((candidate) => (
                                    <div className="col-12" key={candidate.id}>
                                        <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                            <div className="d-flex align-items-center w-250px">
                                                <img src={candidate.profileImage || hero1} className="avatar avatar-md-md rounded-pill shadow-md" alt="" />
                                                <div className="ms-3">
                                                    <Link className="h5 title text-dark">{candidate.fullName}</Link>
                                                    <span className="badge bg-soft-primary rounded-pill ms-2">{candidate.status}</span>
                                                </div>
                                            </div>
                                            <div className="justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                                <span className="text-muted d-flex align-items-center fw-medium mt-md-2">{candidate.domesticCategory}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between d-md-block mt-3 mt-md-0 w-100px">
                                                <span className="">Created At</span>
                                                <span className="text-muted d-flex align-items-center fw-medium mt-md-2"><FiClock className="fea icon-sm me-1 align-middle" />{new Date(candidate.createdAt).toLocaleDateString()}</span>
                                            </div>
                                            <div className="mt-3 mt-md-0">
                                                <Link to={`/candidate-profile-setting/${candidate.id}`}>
                                                    <button className="btn btn-sm btn-primary w-full ms-md-1">Edit</button>
                                                </Link>
                                                <button
                                                    className="btn btn-sm btn-danger w-full ms-md-1"
                                                    onClick={() => handleDelete(candidate.id)}
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div className="col-12">
                                        <div className="job-post job-post-list rounded shadow p-4 d-md-flex align-items-center justify-content-between position-relative">
                                            <div className="text-center">
                                                <h5>No candidates found</h5>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer top={true} />
            <ScrollTop />
        </>
    );
}


