import blog1 from "../assets/images/blog/01.jpg"
import blog2 from "../assets/images/blog/02.jpg"
import blog3 from "../assets/images/blog/03.jpg"
import blog4 from "../assets/images/blog/04.jpg"
import blog5 from "../assets/images/blog/05.jpg"
import blog6 from "../assets/images/blog/06.jpg"
import blog7 from "../assets/images/blog/07.jpg"
import blog8 from "../assets/images/blog/08.jpg"
import blog9 from "../assets/images/blog/09.jpg"


export const blogData = [
    {
      id:1,
      image:blog1,
      title:'Precheck Hire is gaining momentum and expanding',
      date:'8th Jul 2024',
      time:'5 min read',
      tag:'Jobs',
      company:'Admin'
    },
    {
        id:2,
        image:blog2,
        title:'Tackling job insecurity in our own way',
        date:'18th Jun 2024',
        time:'5 min read',
        tag:'Jobs',
        company:'Admin'
      },
      {
        id:3,
        image:blog3,
        title:'Precheck Hire keeps strengthening the medium between domestic workers and employers',
        date:'2nd May 2024',
        time:'5 min read',
        tag:'Jobs',
        company:'Admin'
      },
      {
        id:4,
        image:blog4,
        title:'The cost of Transportation to a groceries Market',
        date:'5th May 2024',
        time:'5 min read',
        tag:'Jobs/Errands',
        company:'Admin'
      },
      {
        id:5,
        image:blog5,
        title:'Should you monitor your maids with a CCTV camera',
        date:'5th May 2024',
        time:'5 min read',
        tag:'Home Security',
        company:'Admin'
      },
      {
        id:6,
        image:blog6,
        title:'How often should the home be tidied within 24 hours',
        date:'4th May 2024',
        time:'5 min read',
        tag:'Health & Hygiene',
        company:'Admin'
      },
      {
        id:7,
        image:blog7,
        title:'KIDS SECTION: The Ideal requires for a Clean and Safe Playground',
        date:'4th May 2024',
        time:'5 min read',
        tag:'Kids/Children',
        company:'Admin'
      },
      {
        id:8,
        image:blog8,
        title:'A Commercial experienced Driver or a chauffeur',
        date:'3rd May 2024',
        time:'6 min read',
        tag:'Driving Cars',
        company:'Admin'
      },
      {
        id:9,
        image:blog9,
        title:'Invest in Quality CCTV Cameras',
        date:'2rd May 2024',
        time:'5 min read',
        tag:'Home Security',
        company:'Admin'
      },
]