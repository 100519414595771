import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiClock, FiCalendar } from "../assets/icons/vander";
import getAllBlogs from "../common/realBackend/blog/getAllBlogs";
import getBlogById from "../common/realBackend/blog/getBlogById";

export default function Blog() {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await getAllBlogs();
                console.log('Blogs fetched successfully:', response);
                setBlogs(response.data.blogs);

                // Fetch each blog by ID and log the response
                response.data.blogs.forEach(async (blog) => {
                    const blogDetails = await getBlogById(blog.id);
                    console.log('Blog details fetched successfully:', blogDetails);
                });

            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <>
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4 mt-0">
                {blogs.slice(0, 3).map((item, index) => {
                    return (
                        <div className="col" key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="card blog blog-primary shadow rounded overflow-hidden border-0" style={{ width: '100%' }}>
                                <div className="card-img blog-image position-relative overflow-hidden rounded-0">
                                    <div className="position-relative overflow-hidden">
                                        <img src={item.backgroundImage} className="img-fluid" alt="" />
                                        <div className="card-overlay"></div>
                                    </div>
                                </div>

                                <div className="card-body blog-content position-relative p-0">
                                    <div className="blog-tag px-4">
                                        <Link to={`/blog-detail/${item.id}`} className="badge bg-primary rounded-pill">Author {item.authorId}</Link>
                                    </div>
                                    <div className="p-4">
                                        <ul className="list-unstyled text-muted small mb-2">
                                            <li className="d-inline-flex align-items-center me-2">
                                                <FiCalendar className="fea icon-ex-sm me-1 text-dark" />{new Date(item.createdAt).toLocaleDateString()}
                                            </li>
                                            <li className="d-inline-flex align-items-center">
                                                <FiClock className="fea icon-ex-sm me-1 text-dark" />{new Date(item.updatedAt).toLocaleTimeString()}
                                            </li>
                                        </ul>

                                        <Link to={`/blog-detail/${item.id}`} className="title fw-semibold fs-5 text-dark">{item.title}</Link>

                                        <ul className="list-unstyled d-flex justify-content-between align-items-center text-muted mb-0 mt-3">
                                            <li className="list-inline-item me-2">
                                                <Link to={`/blog-detail/${item.id}`} className="btn btn-link primary text-dark">Read Now <i className="mdi mdi-arrow-right"></i></Link>
                                            </li>
                                            <li className="list-inline-item">
                                                <span className="text-dark">By</span> <Link to={`/blog-detail/${item.id}`} className="text-muted link-title">Author {item.authorId}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
