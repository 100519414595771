import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavbarDark from '../components/navbarDark';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import getUserProfile from '../common/realBackend/users/GetUserProfile';
import userProfileUpdate from '../common/realBackend/users/UserProfileUpdate';
import { Modal, Button } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa';
import updateMyPassword from '../common/realBackend/users/UpdateMyPassword';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function UserProfileSetting() {
  const [userProfile, setUserProfile] = useState(null);
  const [formData, setFormData] = useState({});
  const [originalEmail, setOriginalEmail] = useState('');
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile();
        setUserProfile(data);
        setFormData({
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          address: data.data.address,
          profileImageUrl: data.data.profileImageUrl,
          userSummaryInfo: data.data.userSummaryInfo,
          subscriptionPlan: data.data.subscriptionPlan,
          email: data.data.email,
          phoneNumber: data.data.phoneNumber,
        });
        setProfileImageUrl(data.data.profileImageUrl);
        setOriginalEmail(data.data.email);
        setOriginalPhoneNumber(data.data.phoneNumber);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfileImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setProfileImageFile(file);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      setIsUpdating(true);

      // Create a new FormData object to include the necessary fields
      const formDataToSend = new FormData();
      formDataToSend.append('firstName', formData.firstName);
      formDataToSend.append('lastName', formData.lastName);
      formDataToSend.append('address', formData.address);
      formDataToSend.append('userSummaryInfo', formData.userSummaryInfo);

      if (formData.subscriptionPlan && formData.subscriptionPlan.id) {
        formDataToSend.append('subscriptionPlanId', formData.subscriptionPlan.id);
      }

      // Only append email and phone number if they have changed
      if (formData.email !== originalEmail) {
        formDataToSend.append('email', formData.email);
      }
      if (formData.phoneNumber !== originalPhoneNumber) {
        formDataToSend.append('phoneNumber', formData.phoneNumber);
      }

      // Only append the image file if a new image was selected
      if (profileImageFile) {
        formDataToSend.append('profileImageUrl', profileImageFile);
      } else {
        formDataToSend.append('profileImageUrl', formData.profileImageUrl);
      }

      // Log the formData content
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}: ${value}`);
      }

      await userProfileUpdate(formDataToSend); // Send FormData
      setIsUpdating(false);
      setShowModal(false);
      setSuccessMessage('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setIsUpdating(false);
      setErrorMessage('Failed to update profile. Please try again.');
    }
  };

  const handlePasswordChange = async () => {
    setSubmitting( true);
    // Reset error and success messages
    setPasswordError(null);
    setPasswordSuccess(null);
  
    // Validate new password and confirm password
    if (newPassword !== confirmPassword) {
      setSubmitting(false); // Reset isUpdating if validation fails
      setPasswordError("New password and confirm password do not match.");
      return;
    }
  
    try {
      // Call the update password API
      const response = await updateMyPassword({
        passwordCurrent: currentPassword,
        password: newPassword,
      });
  
      if (response.status === "fail") {
        setPasswordError(response.message);
      } else {
        setPasswordSuccess("Password updated successfully!");
      }
    } catch (error) {
      setPasswordError("Your current password is wrong.");
    } finally {
      setSubmitting(false); // Ensure isUpdating is reset after the operation
    }
  };

  if (!userProfile) {
    return <p>Loading...</p>;
  }

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    userSummaryInfo,
    subscriptionPlan,
    acceptanceStatus,
  } = userProfile.data;


  return (
    <>
      <NavbarDark />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="position-relative">
                <div className="candidate-cover">
                  <div className="profile-banner relative text-transparent">
                    <div className="relative shrink-0">
                      <img
                        src={profileImageUrl}
                        className="rounded shadow"
                        id="profile-banner"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="candidate-profile d-flex align-items-end mx-2">
                  <div className="position-relative">
                    <div className="position-relative d-inline-block">
                      <img
                        src={profileImageUrl}
                        className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                        id="profile-image"
                        alt=""
                      />
                      <input
                        type="file"
                        accept="image/*"
                        id="profileImageInput"
                        onChange={handleProfileImageChange}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="profileImageInput" style={{ position: 'absolute', bottom: 0, right: 0, cursor: 'pointer' }}>
                        <FaCamera size={20} className="text-dark" />
                      </label>
                    </div>
                  </div>

                  <div className="ms-2">
                    <h5 className="mb-0">{firstName} {lastName}</h5>
                    <p className="text-muted mb-0">{userProfile.data.role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rounded shadow p-4">
                <form>
                  <h5>Personal Details :</h5>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">First Name:</label>
                        <input
                          name="firstName"
                          id="firstName"
                          type="text"
                          className="form-control"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Last Name:</label>
                        <input
                          name="lastName"
                          id="lastName"
                          type="text"
                          className="form-control"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Email:</label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Address:</label>
                        <input
                          name="address"
                          id="address"
                          type="text"
                          className="form-control"
                          value={formData.address}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Phone Number:</label>
                        <input
                          name="phoneNumber"
                          id="phoneNumber"
                          type="text"
                          className="form-control"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">User Summary Info:</label>
                        <textarea
                          name="userSummaryInfo"
                          id="userSummaryInfo"
                          className="form-control"
                          value={formData.userSummaryInfo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Acceptance Status:</label>
                        <div>
                          {acceptanceStatus === 'approved' ? (
                            <span className="badge bg-success">Approved</span>
                          ) : acceptanceStatus === 'rejected' ? (
                            <span className="badge bg-danger">Rejected</span>
                          ) : (
                            <span className="badge bg-warning">Pending</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Subscription Plan:</label>
                        <div>
                          {subscriptionPlan ? (
                            <span className="badge bg-primary">{subscriptionPlan.name}</span>
                          ) : (
                            <span className="badge bg-danger">No Subscription Plan</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    onClick={handleUpdateProfile}
                    disabled={isUpdating}
                  >
                    {isUpdating ? 'Updating...' : 'Update Profile'}
                  </Button>
                  {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
                  {successMessage && <p className="text-success mt-3">{successMessage}</p>}
                </form>
              </div>
            </div>
          </div>
          <div className="rounded shadow p-4 mt-4">
            <div className="row">
              <div className="col-md-12 pt-2">
                <h5>Security :</h5>

                <form>
                  <div className="row mt-4">
                    {/* Current Password Field */}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">
                          Current Password :
                        </label>
                        <input
                          name="currentPassword"
                          id="currentPassword"
                          type={currentPasswordVisible ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter current password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                        <span
                          onClick={toggleCurrentPasswordVisibility}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                          }}
                        >
                          {currentPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>

                    {/* New Password Field */}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">
                          New Password :
                        </label>
                        <input
                          name="newPassword"
                          id="newPassword"
                          type={newPasswordVisible ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                        <span
                          onClick={toggleNewPasswordVisibility}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                          }}
                        >
                          {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>

                    {/* Confirm New Password Field */}
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">
                          Confirm New Password :
                        </label>
                        <input
                          name="confirmPassword"
                          id="confirmPassword"
                          type={confirmPasswordVisible ? "text" : "password"}
                          className="form-control"
                          placeholder="Re-enter new password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                        <span
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                          }}
                        >
                          {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                    </div>

                    {/* Save Password Button */}
                    <div className="col-md-12">
                      <Button
                        variant="primary"
                        onClick={handlePasswordChange}
                        disabled={isSubmitting} // Disable button during submission
                      >
                        {isSubmitting? 'Saving...' : 'Save Password'} {/* Change button text */}
                      </Button>
                    </div>
                    {/* Error Message */}
                      {passwordError && (
                        <div className="col-md-12 mt-3">
                          <p className="text-danger">{passwordError}</p>
                        </div>
                      )}

                      {/* Success Message */}
                      {passwordSuccess && (
                        <div className="col-md-12 mt-3">
                          <p className="text-success">{passwordSuccess}</p>
                        </div>
                      )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
