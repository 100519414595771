import api from '../../../helpers/api_helper';

const blacklistCandidate = async (blacklistData) => {
  try {
    const response = await api.post('/api/v1/candidates/blacklistCandidate', blacklistData);
    console.log('API Response:', response); // Log the full response
    return response.data; // Ensure you return the appropriate data structure
  } catch (error) {
    console.error('Error blacklisting candidate:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default blacklistCandidate;
