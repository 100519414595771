import api from '../../../helpers/api_helper';

const getCandidateDetails = async (id) => {
  try {
    const response = await api.get(`/api/v1/candidates/getCandidateDetails/${id}`);
    console.log('Candidate details fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching candidate details:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getCandidateDetails;
