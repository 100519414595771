import api from '../../../helpers/api_helper'

const signup = async (userData) => {
  try {
    const response = await api.post('/api/v1/users/signup', userData);
    console.log('Signup successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error during signup:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default signup;
