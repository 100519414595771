import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BlacklistModal = ({ show, handleClose, handleReportSubmit, reportMessage, showComment }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Blacklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleReportSubmit}>
                    <Form.Group controlId="comment">
                        <Form.Label className="fw-semibold">Comment <span className="text-danger">*</span></Form.Label>
                        <Form.Control as="textarea" rows={4} placeholder="Add your comment" required />
                    </Form.Group>
                    <div className="d-grid justify-content-end mt-3">
                        <Button type="submit" className="btn btn-danger">Submit</Button>
                    </div>
                </Form>
                {showComment && (
                    <div className="alert alert-info mt-3">
                        {reportMessage}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default BlacklistModal;
