import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bg1 from '../assets/images/hero/bg6.jpg';
import logo from '../assets/images/colored logo.png';
import signup from '../common/realBackend/users/SignUp';

const Signup = () => {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phoneNumber: '',
            address: '',
            terms: false,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            password: Yup.string().required('Password is required'),
            phoneNumber: Yup.string().required('Phone Number is required'),
            address: Yup.string().required('Address is required'),
            terms: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const userData = {
                    ...values,
                    role: 'user',
                    subscriptionPlanId: 1,
                };
                await signup(userData);
                setTimeout(() => {
                    navigate('/email-verification');
                }, 3000);
            } catch (error) {
                setErrors({ submit: error.response?.data?.message || 'Something went wrong!' });
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <section className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="p-4 bg-white rounded shadow-md mx-auto w-100" style={{ maxWidth: '400px', height: '450px', overflowY: 'auto' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Link to="/">
                                    <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" style={{ width: '200px' }} />
                                </Link>
                                <h6 className="mb-3 text-uppercase fw-semibold">Register your account</h6>
                                {/* First Name Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">First Name</label>
                                    <input
                                        name="firstName"
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        {...formik.getFieldProps('firstName')}
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className="text-danger">{formik.errors.firstName}</div>
                                    ) : null}
                                </div>
                                {/* Last Name Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Last Name</label>
                                    <input
                                        name="lastName"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        {...formik.getFieldProps('lastName')}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className="text-danger">{formik.errors.lastName}</div>
                                    ) : null}
                                </div>
                                {/* Email Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Your Email</label>
                                    <input
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="example@gmail.com"
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="text-danger">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                {/* Password Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Password</label>
                                    <input
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        {...formik.getFieldProps('password')}
                                    />
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="text-danger">{formik.errors.password}</div>
                                    ) : null}
                                </div>
                                {/* Phone Number Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Phone Number</label>
                                    <input
                                        name="phoneNumber"
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        {...formik.getFieldProps('phoneNumber')}
                                    />
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <div className="text-danger">{formik.errors.phoneNumber}</div>
                                    ) : null}
                                </div>
                                {/* Address Field */}
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Address</label>
                                    <input
                                        name="address"
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        {...formik.getFieldProps('address')}
                                    />
                                    {formik.touched.address && formik.errors.address ? (
                                        <div className="text-danger">{formik.errors.address}</div>
                                    ) : null}
                                </div>
                                {/* Terms and Conditions Checkbox */}
                                <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="termsCheckbox"
                                        {...formik.getFieldProps('terms')}
                                        style={{
                                            border: '2px solid #007bff',
                                            borderRadius: '0.25rem',
                                            padding: '0.25rem',
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="termsCheckbox">
                                        I Accept <Link to="/terms" className="text-primary">Terms And Conditions</Link>
                                    </label>
                                    {formik.touched.terms && formik.errors.terms ? (
                                        <div className="text-danger">{formik.errors.terms}</div>
                                    ) : null}
                                </div>
                                <button className="btn btn-primary w-100" type="submit" disabled={formik.isSubmitting}>
                                    {formik.isSubmitting ? 'Registering...' : 'Register'}
                                </button>
                                {formik.errors.submit && <div className="alert alert-danger mt-3">{formik.errors.submit}</div>}
                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted small me-2">Already have an account? </span> <Link to="/login" className="text-dark fw-semibold small">Sign in</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Signup;
