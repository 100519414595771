import React, { useState, useEffect } from "react";
import { Form, FormControl, Button } from 'react-bootstrap';
import Navbar from "../../components/navbar";
import HeroSection from "./hero-section";
import getUserMessages from "../../common/realBackend/notifications/GetUserMessages";
import getUser from "../../common/realBackend/users/GetUser";
import { Link } from 'react-router-dom';

export default function Chat() {
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
    const [searchMenuOpen, setSearchMenuOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [chatPartnerProfiles, setChatPartnerProfiles] = useState({});
    const [chatPartnerDetails, setChatPartnerDetails] = useState(null);

    // Fetch user details from local storage
    const getUserDetailsFromLocalStorage = () => {
        const authUser = localStorage.getItem("authUser");
        if (authUser) {
            try {
                const user = JSON.parse(authUser);
                setUserDetails(user.data);
            } catch (error) {
                console.error('Error parsing authUser from localStorage:', error);
            }
        }
    };

    useEffect(() => {
        getUserDetailsFromLocalStorage();
    }, []);

    const userId = userDetails?.id;

    // Fetch user messages and chat partner profiles
    useEffect(() => {
        const fetchMessagesAndProfiles = async () => {
            try {
                const response = await getUserMessages();
                if (response && response.data && response.data.messages) {
                    const messages = response.data.messages;
                    setMessages(messages);
                    setFilteredMessages(messages);

                    // Collect unique chat partner IDs
                    const chatPartnerIds = new Set();
                    messages.forEach((message) => {
                        if (message.sender.id !== userId) {
                            chatPartnerIds.add(message.sender.id);
                        }
                        if (message.receiver.id !== userId) {
                            chatPartnerIds.add(message.receiver.id);
                        }
                    });

                    // Fetch profiles for all chat partners
                    const profiles = await Promise.all(
                        Array.from(chatPartnerIds).map(id => getUser(id))
                    );

                    const profileMap = {};
                    profiles.forEach(profileResponse => {
                        if (profileResponse && profileResponse.data) {
                            profileMap[profileResponse.data.id] = profileResponse.data;
                        }
                    });

                    setChatPartnerProfiles(profileMap);

                } else {
                    console.error('No messages found in the response:', response);
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessagesAndProfiles();
    }, [userId]);

    // Fetch chat partner details when a chat is selected
    useEffect(() => {
        if (selectedChat) {
            const fetchChatPartnerDetails = async () => {
                try {
                    const chatPartnerId = selectedChat.messages[0].sender.id === userId
                        ? selectedChat.messages[0].receiver.id
                        : selectedChat.messages[0].sender.id;

                    const response = await getUser(chatPartnerId);
                    if (response && response.data) {
                        setChatPartnerDetails(response.data);
                    } else {
                        console.error('No user details found for chat partner:', response);
                    }
                } catch (error) {
                    console.error('Error fetching chat partner details:', error);
                }
            };

            fetchChatPartnerDetails();
        }
    }, [selectedChat, userId]);

    // Filter messages based on search query
    useEffect(() => {
        const results = messages.filter((message) =>
            getChatPartnerName(message).toLowerCase().includes(searchQuery.toLowerCase()) ||
            truncateMessage(message.content).toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredMessages(results);
    }, [searchQuery, messages]);

    // Handle chat selection
    const handleChatClick = (message) => {
        const chat = {
            id: message.id,
            messages: [message]
        };
        setSelectedChat(chat);
    };

    // Truncate message if it's too long
    const truncateMessage = (message, maxLength = 50) => {
        return message && message.length > maxLength ? `${message.substring(0, maxLength)}...` : message;
    };

    // Get chat partner's name
    const getChatPartnerName = (message) => {
        if (message && message.sender && message.receiver) {
            return message.sender.id === userId ? message.receiver.fullName : message.sender.fullName;
        }
        return "Unknown";
    };

    // Format message timestamp
    const formatTime = (date) => {
        return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <HeroSection />
            <section className="container mt-5 mb-5">
                <div className="row">
                    {/* Left Side */}
                    <div className="col-md-4 col-lg-4 bg-light border-end d-flex flex-column">
                        <div className="d-flex align-items-center p-3 border-bottom">
                            <img 
                                src={userDetails?.profileImageUrl || "default_image_path"} 
                                alt="Profile" 
                                className="rounded-circle" 
                                width="50" 
                                height="50" 
                            />
                            <div className="ms-3">
                                <h6 className="mb-0">{userDetails?.fullName || "Profile Name"}</h6>
                                <span className="text-muted">
                                    <i className="mdi mdi-circle text-success align-middle me-2" />
                                    Active
                                </span>
                            </div>
                        </div>
                        <div className="p-3 border-bottom">
                            <Form.Control 
                                type="text" 
                                placeholder="Search" 
                                className={`form-control ${searchMenuOpen ? 'form-control-lg' : ''}`}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setSearchMenuOpen(true)}
                                onBlur={() => setSearchMenuOpen(false)}
                            />
                        </div>
                        <div className="p-3 flex-grow-1 overflow-auto" style={{ maxHeight: 'calc(100vh - 400px)' }}>
                            <h6>Recent</h6>
                            <div className="list-group">
                                {filteredMessages && filteredMessages.length > 0 ? (
                                    filteredMessages
                                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                        .slice(0, 5) // Limit to 5 items
                                        .map((message) => (
                                            <div
                                                key={message.id}
                                                onClick={() => handleChatClick(message)}
                                                className="list-group-item list-group-item-action d-flex align-items-center"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img 
                                                    src={message.sender.id === userId 
                                                        ? (chatPartnerProfiles[message.receiver.id]?.profileImageUrl || "default_image_path")
                                                        : (chatPartnerProfiles[message.sender.id]?.profileImageUrl || "default_image_path")} 
                                                    alt="Chat" 
                                                    className="rounded-circle me-2" 
                                                    width="40" 
                                                    height="40" 
                                                />
                                                <div className="d-flex flex-column flex-grow-1 me-3">
                                                    <h6 className="mb-0">{getChatPartnerName(message)}</h6>
                                                    <p className="mb-0 text-muted">{truncateMessage(message.content) || "No recent message"}</p>
                                                </div>
                                                <small className="text-muted">{formatTime(message.createdAt)}</small>
                                            </div>
                                        ))
                                ) : (
                                    <p className="text-muted">No recent chats</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Right Side */}
                    <div className="col-md-8 col-lg-8 d-flex flex-column">
                        {selectedChat ? (
                            <>
                                <div className="d-flex justify-content-between align-items-center border-bottom p-3">
                                    <div>
                                        <h6 className="mb-0">{getChatPartnerName(selectedChat.messages[0])}</h6>
                                        <span className="text-muted">
                                            <i className="mdi mdi-circle text-success align-middle me-2" />
                                            Active Now
                                        </span>
                                    </div>
                                    <div className="text-end mt-2">
                                        <p className="mb-1"><strong>Email:</strong> {chatPartnerDetails?.email || "N/A"}</p>
                                        <p className="mb-1"><strong>Phone:</strong> {chatPartnerDetails?.phone || "N/A"}</p>
                                    </div>
                                </div>

                                <div className="p-3 flex-grow-1 overflow-auto">
                                    {selectedChat.messages.map((message) => (
                                        <div key={message.id} className={`d-flex flex-column ${message.sender.id === userId ? 'align-items-end' : 'align-items-start'} mb-3`}>
                                            <div className={`p-2 rounded mb-1 bg-light text-dark`}>
                                                <p className="mb-0">
                                                    {message.content}
                                                    <br />
                                                    <span>
                                                        View details on 
                                                        <Link to={{
                                                            pathname: `/candidate-profile/${message.candidate.id}`,
                                                            state: { candidate: message.candidate }
                                                        }}>
                                                            {message.candidate.fullName}
                                                        </Link>

                                                    </span>
                                                </p>
                                            </div>
                                            <small className={`text-muted ${message.sender.id === userId ? 'text-end' : 'text-start'}`}>{formatTime(message.createdAt)}</small>
                                        </div>

                                    ))}
                                </div>
                                
                            </>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center flex-grow-1">
                                <p className="text-muted">Select a notification to view messages</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}