import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { servicesData } from "../data/data";
import getReasonsToUseById from '../common/realBackend/contentManagement/getReasonsToUseById'; // Adjust the import path as necessary

export default function AboutTwo() {
    const [reasonsData, setReasonsData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getReasonsToUseById();
                setReasonsData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!reasonsData) {
        return <p>Loading...</p>; // Add a loading state if needed

    }

    return (
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center mb-4 pb-2">
                <div className="col-12">
                    <div className="section-title text-center">
                        <h4 className="title mb-3">{reasonsData.title}</h4>
                        <p className="text-muted para-desc mx-auto mb-0">
                            {reasonsData.subText}
                            
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                {reasonsData.reasons.map((reason, index) => {
                    return (
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2" key={index}>
                            <div className="position-relative features p-4 rounded shadow bg-white h-100 d-flex flex-column">
                                <div className="feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center">
                                    <i className="mdi mdi-check-circle-outline align-middle" style={{ fontSize: '1.3em', color: 'gray' }}></i>
                                </div>

                                <div className="mt-4 flex-grow-1" style={{ textAlign: 'left' }}>
                                    <Link to="" className="title h5 text-dark" style={{ textAlign: 'center', display: 'block' }}>{reason.mainText}</Link>
                                    <p className="text-muted mt-3 mb-0" style={{ textAlign: 'left' }}>{reason.subText}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
