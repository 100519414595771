import api from '../../../helpers/api_helper';

const getHowToGetStartedById = async () => {
  try {
    const response = await api.get('/api/v1/contents/getHowToGetStartedById/1');
    console.log('How to Get Started details fetched successfully:', response.data);
    return response.data.data.howToGetStarted; // Returns the howToGetStarted object
  } catch (error) {
    console.error('Error fetching How to Get Started details:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getHowToGetStartedById;
