import api from '../../../helpers/api_helper';

const getPrivacyPolicy = async () => {
  try {
    const response = await api.get('api/v1/settings/getSettingById/3');
    return response.data;
  } catch (error) {
    console.error('Error fetching privacy policy:', error);
    throw error;
  }
};

export default getPrivacyPolicy;
