export const accordionData1 = [
    {
        title: 'What is the main process to open an account?',
        desc: 'To create an account on Precheck Hire, simply complete the sign-up form with accurate personal information. For full access to all features, you will also need to upload a valid form of identification and pay a subscribption fee'
    },
    {
        title: 'Why is KYC verification Necessary?',
        desc: 'KYC (Know Your Customer) verification is required to maintain a secure and trustworthy platform. It helps ensure that all users are verified, protecting both employers and job seekers.'
    },
    {
        title: 'Can I use the platform without completing KYC?',
        desc: 'No, you cannot access any features beyond the Discover page without completing KYC verification. KYC is essential for accessing the full platform.'
    },
    {
        title: 'What documents are accepted for KYC verification?',
        desc: 'Accepted documents include your NIN (National Identification Number), Driver’s License, or International Passport. Make sure the documents are clear and valid.'
    },
    {
        title: 'How long does it take to verify my KYC?',
        desc: 'KYC verification typically takes 24-48 hours. You will be notified once the process is complete.'
    },
    {
        title: 'What can I access before KYC is verified?',
        desc: 'Before completing KYC verification, you can only view the Discover page. Full access to all platform features is granted once your KYC is approved and a subscription is paid.'
    },
    {
        title: 'Are my information safe with Precheck Hire?',
        desc: 'We take privacy seriously at Precheck Hire. Your data and information are safe with us. Please read through our Privacy Policy for more details..'
    },
];

export const accordionData2 = [
    {
        title: 'How do recommendations work for domestic workers?',
        desc: "Employers can recommend domestic workers they have had a positive experience with. Each recommendation boosts the worker's visibility and increases their chances of being hired."
    },
    {
        title: 'Can employers see the number of recommendations a domestic worker has?',
        desc: 'Yes, employers can see the total number of recommendations and read comments from previous employers, which helps them make informed hiring decisions.'
    },
    {
        title: 'How can a domestic worker receive a direct offer?',
        desc: 'Employers can directly offer jobs to specific workers. Workers can review job details, discuss terms, and accept or decline within a set time frame.'
    },
    {
        title: 'What does it mean to be shortlisted for a job?',
        desc: 'Being shortlisted means the employer is interested in your application, and you are being considered for the role. Blacklist Feature'
    },
];

export const accordionData3 = [
    {
        title: 'What is the blacklist feature?',
        desc: 'The blacklist feature highlights domestic workers with violations, particularly those demonstrating unacceptable behavior. Workers blacklisted multiple times will see a significant reduction in job opportunities.'
    },
    {
        title: 'How can an employer blacklist a worker?',
        desc: 'Employers can submit a blacklist request by completing KYC, making a payment, and providing details of their experience with the worker. Each blacklist entry is reviewed by the admin before approval.'
    },
    {
        title: 'How much does it cost to submit a blacklist request?',
        desc: 'Blacklisting requires payment after completing KYC verification. This fee covers the review process and ensures only serious cases are processed.'
    },
    {
        title: 'How can I view the blacklist?',
        desc: 'Users can sign up as Blacklist Viewers to see the list of blacklisted domestic workers. This access is available with a ₦2,000 monthly subscription, helping employers make safer hiring choices.'
    },
    {
        title: 'Does being blacklisted affect a worker’s hiring chances?',
        desc: 'Yes, blacklisted workers will have a lower chance of being hired, as employers can see this information and may choose to hire other candidates.'
    },
];

export const accordionData4 = [
    {
        title: 'What pages can I access without a subscription?',
        desc: 'Being shortlisted means the employer has shown interest in the worker’s application, and they are under consideration for the role.'
    },
    {
        title: 'What happens if I miss the deadline for accepting a job offer?',
        desc: 'If the worker does not accept the offer within the set time, it will be automatically rejected.'
    },
    {
        title: 'Does being shortlisted multiple times improve a worker’s profile?',
        desc: 'Yes, workers who are frequently shortlisted by employers improve their chances of being hired, as it shows a history of employer interest.'
    },
];
