import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../components/navbar";
import About from "../components/aboutUs"
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { servicesData, teamData } from "../data/data";
import {FiFacebook, FiInstagram, FiTwitter, FiHelpCircle} from "../assets/icons/vander"

import { categoriesData } from "../data/data";

export default function AboutUs(){
    return(
        <>
        <Navbar navClass="defaultscroll sticky" navLight={true}/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg1})`, backgroundPosition:'top'}}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">About Us</h5>
                            <p className="text-white-50 mt-2">
                                Precheck Hire's mission is to streamline hiring processes, providing tailored solutions for job seekers, employers, and blacklist viewers.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">About us</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">

            <div className="container ">
                <div className="row justify-content-center mb-4 pb-2">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h4 className="title mb-3">Here's why you'll love Precheck Hire</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Find domestic worker positions, get personalized job recommendations, and read reviews on thousands of domestic workers worldwide.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                        {servicesData.map((item, index) => {
                            let Icon = item.icon;
                            return (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-4 pt-2 d-flex align-items-stretch" key={index}>
                                    <div className="position-relative features text-center p-4 rounded shadow bg-white w-100 d-flex flex-column" style={{ height: '100%' }}>
                                        <div className="feature-icon bg-soft-primary rounded shadow mx-auto position-relative overflow-hidden d-flex justify-content-center align-items-center" style={{ width: "60px", height: "60px" }}>
                                            <Icon className="fea icon-ex-md" />
                                        </div>

                                        <div className="mt-4 flex-grow-1">
                                            <Link to="" className="title h5 text-dark">{item.title}</Link>
                                            <p className="text-muted mt-3 mb-0">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
            </div>
        </section>
        <section className="section">
        <div className="container ">
                <div className="row justify-content-center mb-4 pb-2">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h4 className="title mb-3">Begin Your Journey with Precheck: For Job Seekers, Employers, <br/>and Blacklists</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Explore tailored pathways for job seekers, employers, and blacklists. Whether you're searching for opportunities, managing hires, or overseeing blacklisted domestic workers, Precheck guides you through each step with streamlined processes.</p>
                        </div>
                    </div>
                </div>
        </div>
            <div className="container">
                <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <h5>Getting started with Precheck Job Seeker</h5>
                    <ul className="list-unstyled mt-4 mb-0" style={{ paddingLeft: '1em' }}>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Create an account</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Complete KYC verification</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Subscribe</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Search and apply for jobs</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Create job vacancy</Link>
                        </li>
                    </ul>
                </div>



                    
                <div className="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5>Getting Started with Precheck Employer</h5>
                    <ul className="list-unstyled mt-4 mb-0" style={{ paddingLeft: '1em' }}>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Register as an employer</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">KYC verification</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Subscribe</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Browse candidates</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Hire and manage candidates</Link>
                        </li>
                    </ul>
                </div>


                <div className="col-lg-6 col-md-6 col-12 mt-4 pt-2">
                    <h5>Getting Started with Precheck Blacklist</h5>
                    <ul className="list-unstyled mt-4 mb-0" style={{ paddingLeft: '1em' }}>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Create an account</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Subscribe</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Understand blacklisting criteria</Link>
                        </li>
                        <li className="mt-2" style={{ color: '#007bff', listStyleType: 'disc', paddingLeft: 0 }}>
                            <Link to="#" className="text-muted">Review blacklisted profiles</Link>
                        </li>
                    </ul>
                </div>

                    
                </div>
            </div>

            <div className="container mt-100 mt-60">

                <div className="row mt-md-5 pt-md-3 mt-4 pt-2 justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                            <h4 className="title mb-4">Have a Question ? Get in touch!</h4>
                            
                            <Link to="/contactus" className="btn btn-primary mt-3"><i className="uil uil-phone"></i> Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        <Footer/>
        <ScrollTop/>
        </>

    )
}