import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { FiMapPin, FiSearch } from "../assets/icons/vander";
import { useNavigate } from "react-router-dom";
import getNigeriaState from "../common/realBackend/users/GetNigeriaState";

export default function FormSelect() {
    const [keyword, setKeyword] = useState("");
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const locations = await getNigeriaState();
                const formattedLocations = locations.data.map(location => ({
                    value: location,
                    label: location
                }));
                setLocationOptions(formattedLocations);
            } catch (error) {
                setError("Failed to load locations");
            } finally {
                setLoading(false);
            }
        };

        fetchLocation();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const location = selectedLocation ? selectedLocation.value : "";
        navigate(`/candidates?keyword=${keyword}&location=${location}`);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            textAlign: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'center',
            width: '100%',
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            width: '100%',
            paddingLeft: '40px',
        }),
        input: (provided) => ({
            ...provided,
            textAlign: 'right',
        }),
        menu: (provided) => ({
            ...provided,
            cursor: 'default',
        }),
    };

    return (
        <form className="card-body text-start" onSubmit={handleSubmit}>
            <div className="registration-form text-dark text-start">
                <div className="row g-lg-0 flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-lg-3 col-md-6 col-12 mb-3 mb-md-0" style={{ flex: 1 }}>
                        <div className="filter-search-form position-relative filter-border">
                            <FiSearch className="fea icon-20 icons" />
                            <input
                                name="name"
                                type="text"
                                id="job-keyword"
                                className="form-control filter-input-box bg-light border-0"
                                placeholder="Search your keywords"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                style={{ cursor: 'text', textAlign: 'left' }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12 mb-3 mb-md-0" style={{ flex: 1 }}>
                        <div className="filter-search-form position-relative filter-border">
                            <FiMapPin className="fea icon-20 icons" />
                            {loading ? (
                                <div>Loading...</div>
                            ) : error ? (
                                <div>{error}</div>
                            ) : (
                                <Select
                                    options={locationOptions}
                                    placeholder="Location"
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    styles={customStyles}
                                />
                            )}
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-12" style={{ flex: 1 }}>
                        <input
                            type="submit"
                            id="search"
                            name="search"
                            style={{ height: '60px', width: '100%' }}
                            className="btn btn-primary searchbtn"
                            value="Search"
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}
