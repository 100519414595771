import api from '../../../helpers/api_helper';

const getAllUserCandidates = async () => {
  try {
    const response = await api.get('/api/v1/candidates/getAllUserCandidates');
    console.log('User candidates fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user candidates:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getAllUserCandidates;
