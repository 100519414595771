import api from '../../../helpers/api_helper';

const updateMyPassword = async (passwordData) => {
  try {
    const response = await api.patch('/api/v1/users/updateMyPassword', passwordData);
    console.log('Password updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default updateMyPassword;
