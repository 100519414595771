import React, { useState } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';

const JobDescriptionModal = ({ show, handleClose, jobData, onSendMessage }) => {
    const [selectedJob, setSelectedJob] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handleSelectChange = (event) => {
        setSelectedJob(event.target.value);
    };

    const handleSendMessage = async () => {
        setIsSending(true); // Set loading state to true when sending starts

        // Call the onSendMessage function passed down as a prop
        await onSendMessage(selectedJob);

        setIsSending(false); // Reset loading state after sending is complete
        handleClose(); // Close the modal after the message is sent
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select a job vacancy (optional)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="info">
                    Selecting a job vacancy is optional. You can send the message directly without selecting a job.
                </Alert>
                <Form.Group controlId="jobSelect">
                    <Form.Label>Job vacancy</Form.Label>
                    <Form.Control as="select" value={selectedJob} onChange={handleSelectChange}>
                        <option value="">Select a job vacancy</option>
                        {Array.isArray(jobData) && jobData.map((job) => (
                            <option key={job.id} value={job.id}>
                                {job.title} - {job.location}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={isSending}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSendMessage} disabled={isSending}>
                    {isSending ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            {' '}
                            Sending...
                        </>
                    ) : 'Send message'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default JobDescriptionModal;
