import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as Link2 } from "react-scroll";

import bg1 from "../assets/images/hero/bg.jpg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { accordionData1 } from "../data/accordionData";
import { accordionData2 } from "../data/accordionData";
import { accordionData3 } from "../data/accordionData";
import { accordionData4 } from "../data/accordionData";

export default function HelpcenterFaq() {
    const [activeAccordion1, setActiveAccordion1] = useState({});
    const [activeAccordion2, setActiveAccordion2] = useState({});
    const [activeAccordion3, setActiveAccordion3] = useState({});
    const [activeAccordion4, setActiveAccordion4] = useState({});

    const toggleAccordion = (accordionState, setAccordionState, index) => {
        setAccordionState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const renderAccordion = (data, state, setState) =>
        data.map((item, index) => (
            <div className="accordion-item rounded mt-2" key={index}>
                <h2 className="accordion-header" id={`heading-${index}`}>
                    <button
                        className={`${
                            state[index] ? "" : "collapsed"
                        } accordion-button border-0 bg-light`}
                        onClick={() => toggleAccordion(state, setState, index)}
                    >
                        {item.title}
                    </button>
                </h2>
                <div
                    id={`collapse-${index}`}
                    className={`${
                        state[index] ? "show" : ""
                    } accordion-collapse border-0 collapse`}
                >
                    <div className="accordion-body text-muted">{item.desc}</div>
                </div>
            </div>
        ));

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section
                className="bg-half-170 d-table w-100"
                style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "top" }}
            >
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                                    Frequently Asked Questions
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    FAQs
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                        viewBox="0 0 2880 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-5 col-12 d-none d-md-block">
                            <div className="rounded shadow p-4 sticky-bar">
                                <ul
                                    className="list-unstyled sidebar-nav mb-0 py-0"
                                    id="navmenu-nav"
                                >
                                    <li className="navbar-item p-0">
                                        <Link2
                                            to="tech"
                                            spy={true}
                                            activeClass="active"
                                            smooth={true}
                                            className="text-base font-medium navbar-link"
                                        >
                                            Account Creation and Setup
                                        </Link2>
                                    </li>
                                    <li className="navbar-item mt-3 p-0">
                                        <Link2
                                            to="general"
                                            spy={true}
                                            activeClass="active"
                                            smooth={true}
                                            className="text-base font-medium navbar-link"
                                        >
                                            Recommendations and Hiring
                                        </Link2>
                                    </li>
                                    <li className="navbar-item mt-3 p-0">
                                        <Link2
                                            to="payment"
                                            spy={true}
                                            activeClass="active"
                                            smooth={true}
                                            className="text-base font-medium navbar-link"
                                        >
                                            Blacklist Feature
                                        </Link2>
                                    </li>
                                    <li className="navbar-item mt-3 p-0">
                                        <Link2
                                            to="support"
                                            spy={true}
                                            activeClass="active"
                                            smooth={true}
                                            className="text-base font-medium navbar-link"
                                        >
                                            Platform Access and Navigation
                                        </Link2>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-7 col-12">
                            <div className="section-title" id="tech">
                                <h4>Account Creation and Setup</h4>
                                <div className="accordion mt-4 pt-2">
                                    {renderAccordion(
                                        accordionData1,
                                        activeAccordion1,
                                        setActiveAccordion1
                                    )}
                                </div>
                            </div>

                            <div className="section-title mt-5" id="general">
                                <h4>Recommendations and Hiring</h4>
                                <div className="accordion mt-4 pt-2">
                                    {renderAccordion(
                                        accordionData2,
                                        activeAccordion2,
                                        setActiveAccordion2
                                    )}
                                </div>
                            </div>

                            <div className="section-title mt-5" id="payment">
                                <h4>Blacklist Feature</h4>
                                <div className="accordion mt-4 pt-2">
                                    {renderAccordion(
                                        accordionData3,
                                        activeAccordion3,
                                        setActiveAccordion3
                                    )}
                                </div>
                            </div>

                            <div className="section-title mt-5" id="support">
                                <h4>Platform Access and Navigation</h4>
                                <div className="accordion mt-4 pt-2">
                                    {renderAccordion(
                                        accordionData4,
                                        activeAccordion4,
                                        setActiveAccordion4
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
