import api from '../../../helpers/api_helper';

const deleteBookmark = async (id) => {
  try {
    const response = await api.delete(`/api/v1/bookmarks/${id}`);
    console.log('Bookmark deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting bookmark:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default deleteBookmark;
