import api from '../../../helpers/api_helper';

const createCandidate = async (candidateData) => {
  try {
    const response = await api.post('/api/v1/candidates/createCandidate', candidateData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Use 'multipart/form-data' for FormData
      }
    });
    console.log('Candidate created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating candidate:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default createCandidate;
