import {React, useState, useEffect} from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg";

import Navbar from "../components/navbar";
import Faq from "../components/faq";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { FiArrowRightCircle } from "../assets/icons/vander";

import getAllSubscriptionPlans from "../common/realBackend/subscription/GetAllSubscriptionPlans";

export default function Pricing() {
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);

    // Define URLs for each plan using plan ID as the key
    const planLinks = {
        "2": "https://employer.precheckhire.com/#/signup",
        "1": "https://job.precheckhire.com/#/signup",
        "3": "https://blacklist.precheckhire.com/#/signup",
        // Add more mappings as needed
    };

    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            try {
                const response = await getAllSubscriptionPlans();
                if (response && response.data && response.data.subscriptionPlans) {
                    setSubscriptionPlans(response.data.subscriptionPlans);
                    console.log('Subscription plans fetched successfully:', response.data.subscriptionPlans);
                    console.log('Plan Links:', planLinks); // Log the plan links for reference
                } else {
                    console.error('Invalid data format:', response);
                }
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
            }
        };

        fetchSubscriptionPlans();
    }, []);


    return (
        <>
            <Navbar />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Pricing Plans</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        {subscriptionPlans.map(plan => (

                            <div key={plan.id} className="col-lg-3 col-md-6 col-12">
                                <div className="pricing text-center rounded position-relative shadow">
                                    <div className="price-header rounded-top pt-5 pb-5 bg-primary">
                                        <h5 className="price-title text-white">{plan.name}</h5>

                                        <p className="mb-0 text-white-50">{plan.description}</p>
                                    </div>
                                    <div className="d-flex justify-content-center bg-light border-bottom py-4">
                                        <span className="h6 mb-0">₦</span>
                                        <span className="price h4 mb-0 ms-1">{plan.price}</span>
                                        <span className="h6 align-self-end mb-1">/mo</span>
                                    </div>
                                    <div className="pricing-features text-start">
                                        <ul className="feature list-unstyled py-4 p-3 mb-0">
                                            {plan.features.map((feature, index) => (
                                                <li key={index} className="feature-list text-muted mt-2">

                                                    <FiArrowRightCircle className="fea icon-sm text-primary me-2" />
                                      {feature}
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="border-top text-center py-4">

                                            {console.log(`Selected link for ${plan.id}:`, planLinks[plan.id])}
                                            <a 
                                                href={planLinks[plan.id] || "https://example.com/default"} 
                                                className="btn btn-primary"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Subscribe Now
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
