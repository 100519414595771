import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import getHowToGetStartedById from "../common/realBackend/contentManagement/getHowToGetStartedById";
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import domestic2 from '../assets/images/medium-shot-african-mother-holding-little-girl.jpg';
import domestic1 from '../assets/images/woman-smiles-front-large-brush-front-bed.jpg';

export default function AboutUs({ containerClass }) {
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await getHowToGetStartedById();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const { images, youtubeLink, mainText, subText, steps } = data;

    return (
        <>
            <div className={containerClass}>
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6 col-md-6 mb-5">
                        <div className="about-left">
                            <div className="position-relative shadow rounded img-one">
                                <img 
                                    src={domestic1} 
                                    className="img-fluid rounded" 
                                    alt="About Us 1" 
                                    style={{ height: '500px', objectFit: 'cover' }} // Added style for domestic1
                                />
                            </div>

                            <div className="img-two shadow rounded p-2 bg-white">
                                <img 
                                    src={domestic2} 
                                    className="img-fluid rounded" 
                                    alt="About Us 2" 
                                    style={{ height: '300px', objectFit: 'cover' }} // Added style for domestic2
                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="section-title ms-lg-5">
                            <h4 className="title mb-3">Register Now to Connect with   <br/>Employers and Secure Jobs </h4>
                            <p className="text-muted para-desc mb-0">
                                We make it easy for you to connect with employers looking for reliable domestic workers. Our platform helps you find jobs that match your skills, so you can start working quickly and with confidence.
                            </p>

                            <ul className="list-unstyled text-muted mb-0 mt-3">
                                <div>
                                    <li className="mb-1" style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'grey' }}>
                                        <span className="text-primary h5 me-2">
                                            <i className="mdi mdi-check-circle-outline align-middle" style={{ fontSize: '1.3em' }}></i>
                                        </span>Create Your Profile
                                    </li>
                                    <p className="text-muted para-desc mb-0">
                                        Create your profile to connect with employers and find domestic job opportunities. For safety and trust on our
                                         platform, complete our KYC verification process. KYC 1 covers basic identity
                                         checks, while KYC 2 provides full verification, ensuring a secure environment for both workers and employers.
                                    </p>
                                    
                                    <li className="my-2" style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'grey' }}>
                                        <span className="text-primary h5 me-2">
                                            <i className="mdi mdi-check-circle-outline align-middle" style={{ fontSize: '1.3em' }}></i>
                                        </span>Browse Job Listings/Post Vacancy
                                    </li>
                                    <p className="text-muted para-desc mb-0">
                                        Browse job opportunities that match your skills or find the right domestic worker for your needs.
                                         Easily filter by location and type to find the perfect match, whether you're applying for a job or hiring talent.
                                    </p>

                                    <li className="my-2" style={{ fontSize: '1.2em', fontWeight: 'bold', color: 'grey' }}>
                                        <span className="text-primary h5 me-2">
                                            <i className="mdi mdi-check-circle-outline align-middle" style={{ fontSize: '1.3em' }}></i>
                                        </span>Apply with Confidence
                                    </li>
                                    <p className="text-muted para-desc mb-0">
                                        Our platform bridges the gap between job seekers and employers, offering a seamless experience 
                                        for finding the perfect match. Whether you're looking to
                                         hire top talent or land your dream job, trust us to make the process simple, secure, and rewarding. Start your journey with confidence today!
                                    </p>
                                </div>
                            </ul>

                            <div className="mt-4">
    <Link to="https://job.precheckhire.com/#/login" className="btn btn-primary me-2">
        Sign Up As Job Seeker <i className="mdi mdi-arrow-right align-middle"></i>
    </Link>
    <Link to="https://employer.precheckhire.com/#/login" className="btn btn-primary">
        Sign Up As Employer <i className="mdi mdi-arrow-right align-middle"></i>
    </Link>
</div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
