import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import bg7 from "../assets/images/hero/bg7.jpg";
import Navbar from "../components/navbar";
import BlogsSidebars from "../components/blogsSidebars";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import getBlogById from "../common/realBackend/blog/getBlogById";

export default function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await getBlogById(id);
        console.log('Blog fetched successfully:', response);

        if (response.status === 'success' && response.data && response.data.blog) {
          setBlog(response.data.blog);
        } else {
          setError('Blog data not found.');
        }
      } catch (error) {
        setError('Failed to fetch blog details.');
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar navClass="defaultscroll sticky" navLight={true} />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg7})`, backgroundPosition: 'top' }}>
    
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <span className="badge bg-primary">{blog.tag || 'Precheck Hire'}</span>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-4">
                  {blog.title || 'The Importance of Domestic Work in African Households'}
                </h5>

                <ul className="list-inline text-center mb-0">
                  <li className="list-inline-item mx-4 mt-4">
                    <span className="text-white-50 d-block">Author</span>
                    <Link to="#" className="text-white title-dark">
                      {blog.company || 'Precheck Hire'}
                    </Link>
                  </li>

                  <li className="list-inline-item mx-4 mt-4">
                    <span className="text-white-50 d-block">Date</span>
                    <span className="text-white title-dark">
                      {new Date(blog.createdAt).toLocaleDateString() || '19th June 2023'}
                    </span>
                  </li>

                  <li className="list-inline-item mx-4 mt-4">
                    <span className="text-white-50 d-block">Read Time</span>
                    <span className="text-white title-dark">{blog.readTime || '8 min read'}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Precheck Hire</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Detail
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8 col-md-7">
              <div className="card border-0 shadow rounded overflow-hidden">
                <img
                  src={blog.backgroundImage || bg7}
                  className="img-fluid"
                  alt={blog.title || 'Blog Image'}
                />

                <div className="card-body">
                  <p className="text-muted">{blog.content || 'Content not available.'}</p>

                  <blockquote className="text-center mx-auto blockquote">
                    <i className="mdi mdi-format-quote-open mdi-48px text-muted opacity-2 d-block"></i>
                    "Domestic workers are the unsung heroes of many households, providing essential services that allow families to thrive."
                    <small className="d-block text-muted mt-2">- Precheck Hire</small>
                  </blockquote>
                </div>
              </div>

              {/* Comments Section */}
              {/* <div className="card shadow rounded border-0 mt-4">
                <div className="card-body">
                  <h5 className="card-title mb-0">Comments :</h5> */}

                  {/* Replace with dynamic comments */}
                  {/* Sample comments data */}
                  {/* <ul className="media-list list-unstyled mb-0"> */}
                    {/* Replace with real comments */}
                    {/* {commentsData.map((item, index) => (
                      <li className="mt-4" key={index}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <Link className="pe-3" to="#">
                              <img
                                src={item.image}
                                className="img-fluid avatar avatar-md-sm rounded-circle shadow"
                                alt="img"
                              />
                            </Link>
                            <div className="commentor-detail">
                              <h6 className="mb-0">
                                <Link
                                  to="#"
                                  className="text-dark media-heading"
                                >
                                  {item.name}
                                </Link>
                              </h6>
                              <small className="text-muted">{item.time}</small>
                            </div>
                          </div>
                          <Link to="#" className="text-muted">
                            <i className="mdi mdi-reply"></i> Reply
                          </Link>
                        </div>
                        <div className="mt-3">
                          <p className="text-muted fst-italic p-3 bg-light rounded">
                            {item.desc}
                          </p>
                        </div>
                      </li>
                    ))} */}
                  {/* </ul>
                </div>
              </div> */}

              {/* Comment Form */}
              <div className="card shadow rounded mt-4">
                <div className="card-body">
                  <h5 className="card-title mb-0">Leave A Comment :</h5>

                  <form className="mt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Your Comment</label>
                          <textarea
                            id="message"
                            placeholder="Your Comment"
                            rows="5"
                            name="message"
                            className="form-control"
                            required=""
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Your Email <span className="text-danger">*</span>
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="form-control"
                            required=""
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="send d-grid">
                          <button type="submit" className="btn btn-primary">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <BlogsSidebars />
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );

}


