import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { getInitials, formatSalary } from "../../components/utils";
import NavbarDark from "../../components/navbarDark";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import blacklistCandidate from "../../common/realBackend/candidates/BlacklistCandidate";
import postReview from "../../common/realBackend/candidates/PostReview";
import getUserJobs from "../../common/realBackend/jobs/GetUserJobs";
import getCandidateDetails from "../../common/realBackend/candidates/GetCandidateDetails";
import StarRating from "../../components/StarRating";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hero1 from '../../assets/images/hero1.png';
import profile from '../../assets/images/profile.jpg';
import { FiMail, FiGift, FiHome, FiMapPin, FiGlobe, FiPhone, FiDownload } from "../../assets/icons/vander";

// Import the separated modals
import JobDescriptionModal from "./JobDescriptionModal";
import BlacklistModal from "./BlacklistModal";

import sendMessage from "../../common/realBackend/notifications/SendMessage";

export default function CandidateProfile() {
    let params = useParams();
    let id = params.id;

    const [showJobModal, setShowJobModal] = useState(false);
    const [jobData, setJobData] = useState({ jobs: [] });
    const [error, setError] = useState(null);
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [reportMessage, setReportMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [blacklistshowModal, setBlacklistShowModal] = useState(false);
    const [blacklistshowComment, setBlacklistShowComment] = useState(false);
    const [candidate, setCandidate] = useState(null);

    useEffect(() => {
        const fetchJobData = async () => {
            try {
                const result = await getUserJobs();
                if (result.status === 'success') {
                    setJobData(result.data.jobs);
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError(err.message);
            }
        };
        fetchJobData();
    }, []);

    const location = useLocation();
    useEffect(() => {
        const fetchCandidateDetails = async () => {
            const candidateFromLocation = location.state?.candidate;

            if (candidateFromLocation) {
                setCandidate(candidateFromLocation);
            } else {
                try {
                    const details = await getCandidateDetails(id);
                    setCandidate(details.data.candidate);
                } catch (error) {
                    console.error("Error fetching candidate details:", error);
                }
            }
        };
        fetchCandidateDetails();
    }, [location.state, id]);

    if (!candidate) {
        return <div>Loading...</div>;
    }

    const handleShowJobModal = () => {
        setShowJobModal(true);
    };
    const handleCloseJobModal = () => setShowJobModal(false);

    const handleReviewSubmit = async (e) => {
        e.preventDefault();

        if (!id) {
            console.error("Candidate ID is not available");
            return;
        }

        const reviewData = {
            rating: rating,
            comment: reviewText,
            candidateId: id
        };

        try {
            const result = await postReview(reviewData);
            if (result.status === 'success') {
                toast.success("Review submitted successfully!");
            } else {
                toast.error(result.message || "Review submission failed");
            }
        } catch (error) {
            toast.error("Error submitting review: " + error.message);
        }
    };

    const handleReportSubmit = async (e) => {
        e.preventDefault();
        const comment = e.target.comment.value;

        if (candidate?.blacklistCount >= 5) {
            toast.info("This candidate has already been blacklisted.");
            setBlacklistShowModal(false);
            return;
        }

        const blacklistPayload = {
            comment: comment,
            candidateId: id
        };

        try {
            const result = await blacklistCandidate(blacklistPayload);
            if (result.status === 'success') {
                toast.success("Candidate blacklisted successfully!");
            } else {
                toast.error(result.message || "Failed to blacklist candidate.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }

        setBlacklistShowModal(false);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleShowBlacklistModal = () => setBlacklistShowModal(true);
    const handleCloseBlacklistModal = () => setBlacklistShowModal(false);

    const handleSendMessage = async (selectedJob) => {
        try {
            let messageContent = "I am interested in your candidate";

            // Include job details if a job vacancy is selected
            if (selectedJob) {
                const selectedJobDetails = jobData.find(job => job.id === selectedJob);
                messageContent += ` for the position of ${selectedJobDetails.title} in ${selectedJobDetails.location}.`;
            }

            await sendMessage(candidate.id, messageContent);
            toast.success("Message sent successfully!");
        } catch (error) {
            toast.error("Failed to send message: " + error.message);
        }
    };

    return (
        <>
            <NavbarDark />
            <section className="section">
                <div className="container ">
                    <div className="row">
                        <div className="col-12">
                            <div className="position-relative">
                                <div className="candidate-cover mb-4">
                                    <img
                                        src={candidate.backgroundImage || candidate.profileImage || hero1}

                                        className="img-fluid rounded shadow"
                                        alt=""
                                    />
                                </div>
                                <div className="candidate-profile d-flex align-items-end justify-content-between mx-2">
                                    <div className="d-flex align-items-end">
                                        <img src={candidate.profileImage || hero1} className="rounded-pill shadow border border-3 avatar avatar-medium" alt="" />

                                        <div className="ms-2">
                                            <h5 className="mb-0">{candidate.name}</h5>
                                            <p className="text-muted mb-0">
                                                {candidate.post}
                                                {/* {candidate.DomesticCategories && candidate.DomesticCategories.length > 0 ? (
                                                    candidate.DomesticCategories.map((category) => (
                                                        <div key={category.id}>{category.name}</div>
                                                    ))
                                                ) : (
                                                    <p>No domestic categories available.</p>
                                                )} */}
                                            </p>

                                        </div>

                                        <div className="ms-auto d-flex">
                                            <Button variant="danger" className="ms-2" onClick={handleShowBlacklistModal}>Blacklist</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-4">
                    <div className="row g-4">
                        <div className="col-lg-8 col-md-7 col-12">
                            <h5 className="mb-4">About:</h5>

                            <p className="text-muted">{candidate.about} </p>
                            <h5 className="mt-4">Education:</h5>
                            <div className="row">
                                {candidate.education && candidate.education.length > 0 ? (
                                    candidate.education.map((edu, index) => (
                                        <div className="col-12 mt-4" key={index}>
                                            <div className="d-flex">
                                                <div className="text-center">
                                                    <div className="avatar avatar-small bg-white shadow p-2 rounded d-flex align-items-center justify-content-center">
                                                        <span className="initials">{getInitials(edu.institution)}</span>
                                                    </div>
                                                </div>
                                                <div className="ms-3">
                                                    <h6 className="mb-0">{edu.degree}</h6>
                                                    <p className="text-muted">{edu.institution}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-12 mt-4">
                                        <p>No education information available</p>
                                    </div>
                                )}

                                <h5 className="mt-4">Ratings:</h5>
                                <div className="row">
                                    {candidate.reviews && candidate.reviews.length > 0 ? (
                                        candidate.reviews.map((review, index) => (
                                            <div className="col-12 mt-4" key={index}>
                                                <div className="d-flex">
                                                    <div className="text-center">
                                                        <img
                                                            src={profile} // Replace with actual image if available
                                                            className="avatar avatar-small bg-white shadow p-2 rounded"
                                                            alt=""
                                                        />
                                                        <h6 className="text-muted mt-2 mb-0">
                                                            {review.createdAt.split('T')[0]} {/* Display date */}
                                                        </h6>
                                                    </div>
                                                    <div className="ms-3">
                                                        <h6 className="mb-0">{review.reviewerName || "Anonymous"}</h6>
                                                        <div className="text-warning">
                                                            {[...Array(review.rating || 0)].map((_, i) => (
                                                                <FontAwesomeIcon key={i} icon={faStar} />
                                                            ))}
                                                        </div>
                                                        <p className="text-muted mb-0">
                                                            <em>{review.comment || "No comments available."}</em>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-12 mt-4">
                                            <p>No review available</p>
                                        </div>
                                    )}
                                </div>

                                <div className="p-4 rounded shadow mt-4">
                                    <h5>Add a review !</h5>
                                    <form className="mt-4" onSubmit={handleReviewSubmit}>
                                        <div className="row">

                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">
                                                        Your Rating <span className="text-danger">*</span>
                                                    </label>
                                                    <StarRating rating={rating} onRatingChange={setRating} />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">
                                                        Review <span className="text-danger">*</span>
                                                    </label>
                                                    <textarea name="review" id="review" rows="4" onChange={(e) => setReviewText(e.target.value)} value={reviewText} className="form-control" placeholder="Add
                                         your review"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-grid justify-content-end">
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button
                                                        type="submit"
                                                        id="submit"
                                                        name="send"
                                                        className="btn btn-primary"
                                                    >
                                                        Submit Review
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12">
                            <div className="card bg-light p-4 rounded shadow sticky-bar">
                                <h5 className="mb-0">Profile Overview:</h5>
                                <div className="mt-3">

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium">
                                            <FiMail className="fea icon-sm me-2" /> Categories:
                                        </span>
                                        <span className="fw-medium">
                                            {candidate.post}
                                            {/* {candidate.DomesticCategories && candidate.DomesticCategories.length > 0 ? (
                                                candidate.DomesticCategories.map((category) => (
                                                    <div key={category.id}>{category.name}</div>
                                                ))
                                            ) : (
                                                "No domestic categories available."
                                            )} */}
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium">
                                            <FiGift className="fea icon-sm me-2" /> Offered Salary:
                                        </span>
                                        <span className="fw-medium">
                                            {candidate.salaryRangeMin != null && candidate.salaryRangeMax != null
                                                ? `${formatSalary(candidate.salaryRangeMin)} - ${formatSalary(candidate.salaryRangeMax)}`
                                                : "No salary specified"
                                            }
                                        </span>
                                    </div>


                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium">
                                            <FiHome className="fea icon-sm me-2" /> Languages:
                                        </span>
                                        <span className="fw-medium">
                                            {candidate.languagesSpoken && candidate.languagesSpoken.length > 0
                                                ? candidate.languagesSpoken.join(', ')

                                                : "No languages specified"
                                            }
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium"><FiMapPin className="fea icon-sm me-2" /> Experience:</span>

                                        <span className="fw-medium">{candidate.experience} Years </span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium">
                                            <FiGlobe className="fea icon-sm me-2" /> Qualification:
                                        </span>
                                        <span className="fw-medium">
                                            {candidate.education && candidate.education.length > 0 ? (
                                                candidate.education.length > 0 ? (
                                                    candidate.education.map((edu, index) => (
                                                        <div key={index}>{edu.degree || "No degree specified"}</div>
                                                    ))
                                                ) : (
                                                    "No degree specified."
                                                )
                                            ) : (
                                                "No degree specified."
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium"><FiPhone className="fea icon-sm me-2" /> Mobile:</span>
                                        <span className="fw-medium">{candidate.phone}</span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium"><FiMail className="fea icon-sm me-2" /> Address:</span>
                                        <span className="fw-medium">{candidate.location}</span>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        <span className="d-inline-flex align-items-center text-muted fw-medium"><FiPhone className="fea icon-sm me-2" /> Views:</span>
                                        <span className="fw-medium">58</span>
                                    </div>

                                    <div className="profile-actions">
                                        <Button onClick={handleShowJobModal} className="btn btn-danger w-100">
                                            <FiMail className="fea icon-sm me-2" /> Send a message
                                        </Button>
                                    </div>

                                    <div className="p-3 rounded shadow bg-white mt-2">

                                        <Link to={candidate.resume} download="pdf" target='_blank' className="btn btn-primary w-100"><FiDownload className="fea icon-sm me-1" /> Download CV</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer top={true} />
            <ScrollTop />
            <ToastContainer />

            {/* Modals */}
            <JobDescriptionModal
                show={showJobModal}
                handleClose={handleCloseJobModal}
                jobData={jobData}
                onSendMessage={handleSendMessage}
            />
            <BlacklistModal
                show={blacklistshowModal}
                handleClose={handleCloseBlacklistModal}
                handleReportSubmit={handleReportSubmit}
                reportMessage={reportMessage}
                showComment={blacklistshowComment}
            />
        </>
    );
}
