import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import bg1 from "../assets/images/hero/bg.jpg";
import getPrivacyPolicy from "../common/realBackend/contentManagement/getPrivacyPolicy";

export default function Privacy() {
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrivacyPolicy = async () => {
            try {
                const data = await getPrivacyPolicy();
                if (data.status === "success" && data.data?.setting?.value) {
                    const value = data.data.setting.value;
    
                    try {
                        const content = JSON.parse(value);
                        setPrivacyPolicy(content.blocks[0]?.text || "Content not available");
                    } catch (e) {
                        setPrivacyPolicy(value);
                    }
                } else {
                    setError('Privacy policy content is not available.');
                }
                setLoading(false);
            } catch (error) {
                setError('Failed to load privacy policy');
                setLoading(false);
            }
        };
    
        fetchPrivacyPolicy();
    }, []);    

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Privacy Policy</h5>
                            </div>
                        </div>
                    </div>
                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Privacy</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="card shadow rounded border-0">
                                <div className="card-body">
                                    {privacyPolicy ? (
                                        <p className="text-muted">{privacyPolicy}</p>
                                    ) : (
                                        <p className="text-muted">Privacy policy content is not available at the moment.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
