import React, { useState } from 'react';

const StarRating = ({ rating, onRatingChange }) => {
    const [hover, setHover] = useState(null);

    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;

                return (
                    <label key={index}>
                        <input
                            type="radio"
                            name="rating"
                            value={ratingValue}
                            onClick={() => onRatingChange(ratingValue)}
                            style={{ display: 'none' }}
                        />
                        <span
                            className="star"
                            onMouseEnter={() => setHover(ratingValue)}
                            onMouseLeave={() => setHover(null)}
                            style={{
                                color: ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9",
                                cursor: "pointer",
                                fontSize: "24px" // Adjust the size as needed
                            }}
                        >
                            &#9733;
                        </span>
                    </label>
                );
            })}
        </div>
    );
};

export default StarRating;
