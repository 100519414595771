import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import getAllBlogs from "../common/realBackend/blog/getAllBlogs";

export default function BlogsSidebars() {
  const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await getAllBlogs();
        console.log("Blogs fetched successfully:", response);
        setBlogs(response.data.blogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Filtered blogs based on search query
  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="col-lg-4 col-md-6 col-12">
      <div className="card bg-white p-4 rounded shadow sticky-bar">
        <div>
          <h6 className="pt-2 pb-2 bg-light rounded text-center">Search</h6>

          <div className="search-bar mt-4">
            <div id="itemSearch2" className="menu-search mb-0">
              <form
                className="searchform"
                onSubmit={e => e.preventDefault()} // Prevent page reload on form submit
              >
                <input
                  type="text"
                  className="form-control rounded border"
                  name="s"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)} // Update search query
                />
              </form>
            </div>
          </div>
        </div>

        <div className="mt-4 pt-2">
          <h6 className="pt-2 pb-2 bg-light rounded text-center">Recent Post</h6>
          <div className="mt-4">
            {filteredBlogs.map((item, index) => (
              <div
                className="blog blog-primary d-flex align-items-center mt-3"
                key={index}
              >
                <img
                  src={item.backgroundImage}
                  className="avatar avatar-small rounded"
                  style={{ width: "auto" }}
                  alt=""
                />
                <div className="flex-1 ms-3">
                  <Link to="" className="d-block title text-dark fw-medium">
                    {item.title}
                  </Link>
                  <span className="text-muted small">
                    {new Date(item.createdAt).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
