import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import bg1 from "../assets/images/hero/bg.jpg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import getNigeriaState from "../common/realBackend/users/GetNigeriaState";
import getNigeriaLGAbyStateName from "../common/realBackend/users/GetNigeriaLGAbyStateName";
import getAllDomesticCategories from "../common/realBackend/domesticCategory/GetAllDomesticCategories";
import createJob from "../common/realBackend/jobs/CreateJob";

export default function JobApply() {
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllDomesticCategories();
        if (Array.isArray(response)) {
          setCategories(response);
        } else {
          console.warn("No categories found in response");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchStates = async () => {
      try {
        const response = await getNigeriaState();
        if (Array.isArray(response.data)) {
          setStates(response.data);
        } else {
          console.warn("No states found in response");
        }
      } catch (error) {
        console.error("Error fetching States:", error);
      }
    };

    fetchCategories();
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchLgas = async () => {
      if (selectedState) {
        try {
          const response = await getNigeriaLGAbyStateName(selectedState);
          if (Array.isArray(response.data.lgas)) {
            setLgas(response.data.lgas);
          } else {
            console.warn("No LGAs found in response or incorrect structure");
          }
        } catch (error) {
          console.error("Error fetching LGAs:", error);
        }
      } else {
        setLgas([]); // Clear LGAs if no state is selected
      }
    };

    fetchLgas();
  }, [selectedState]);

  return (
    <>
      <Navbar navClass="defaultscroll sticky" navLight={true} />
      <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                                    Create a Job Vacancy
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                                <li className="breadcrumb-item"><Link to="/job-grid-one">Job</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create a job vacancy</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-7">
              <div className="card border-0">
                <Formik
                  initialValues={{
                    title: "",
                    experience: "",
                    state: "",
                    lga: "",
                    description: "",
                    category: "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const jobData = {
                        title: values.title,
                        description: values.description,
                        domesticCategoryIds: [parseInt(values.category, 10)],
                        experience: values.experience,
                        location: `${values.state}, ${values.lga}`,
                      };

                      console.log("FormData:", jobData); // Log form data before sending

                      const response = await createJob(jobData);

                      if (response.status === 'success') {
                        setSuccessMessage('Job created successfully!');
                        setErrorMessage('');
                      } else {
                        setErrorMessage('Error creating job. Please try again.');
                        setSuccessMessage('');
                      }

                      console.log("API Success:", response.data);
                    } catch (error) {
                      console.error("API Failure:", error);
                      setErrorMessage('Error creating job. Please try again.');
                      setSuccessMessage('');
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting, handleChange, values }) => (
                    <Form className="rounded shadow p-4">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label fw-semibold">
                              Title of Job :
                            </label>
                            <Field
                              name="title"
                              className="form-control"
                              placeholder="Title :"
                              required
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label className="form-label fw-semibold">
                              Job Category:
                            </label>
                            <Field
                              as="select"
                              name="category"
                              className="form-select"
                              required
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="category"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label fw-semibold">
                              Experience :
                            </label>
                            <Field
                              name="experience"
                              className="form-control"
                              placeholder="3+ years experience in Driving"
                              required
                            />
                            <ErrorMessage
                              name="experience"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="state"
                                  className="form-label fw-semibold"
                                >
                                  State:
                                </label>
                                <Field
                                  as="select"
                                  name="state"
                                  className="form-select"
                                  required
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSelectedState(e.target.value);
                                  }}
                                  value={values.state}
                                >
                                  <option value="">Select State</option>
                                  {states.map((state) => (
                                    <option key={state} value={state}>
                                      {state}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="lga"
                                  className="form-label fw-semibold"
                                >
                                  LGA:
                                </label>
                                <Field
                                  as="select"
                                  name="lga"
                                  className="form-select"
                                  required
                                  value={values.lga}
                                  onChange={handleChange}
                                >
                                  <option value="">Select LGA</option>
                                  {lgas.map((lga, index) => (
                                    <option key={index} value={lga}>
                                      {lga}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="lga"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label fw-semibold">
                              Description :
                            </label>
                            <Field
                              as="textarea"
                              name="description"
                              rows="4"
                              className="form-control"
                              placeholder="Describe the job :"
                              required
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="container">
                          <div className="row justify-content-start">
                            <div className="col-auto">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Creating..." : "Create Job"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollTop />
      <Footer />
    </>
  );
}
