import api from '../../../helpers/api_helper';

const login = async (loginData) => {
  try {
    const response = await api.post('/api/v1/users/login', loginData);
    console.log('Login successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error during login:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default login;
