import api from '../../../helpers/api_helper';

const getTermsOfService = async () => {
  try {
    const response = await api.get('api/v1/settings/getSettingById/2');
    return response.data;
  } catch (error) {
    console.error('Error fetching terms of service:', error);
    throw error;
  }
};

export default getTermsOfService;
