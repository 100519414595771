// src/pages/login.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import login from '../common/realBackend/users/Login';
import bg1 from '../assets/images/hero/bg6.jpg';
import logo from '../assets/images/colored logo.png';

export default function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: values => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.password) {
                errors.password = 'Required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            setLoading(true);
            setError('');
            try {
                const response = await login(values);
                if (response.status === 'success') {
                    // Save the entire response to local storage
                    localStorage.setItem('authUser', JSON.stringify(response));
                    // Navigate to a different page if needed
                    navigate('/');
                } else {
                    setError(response.message || 'Login failed. Please check your credentials.');
                }
            } catch (err) {
                setError('Login failed. Please check your credentials.');
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <section className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="p-4 bg-white rounded shadow-md mx-auto w-100" style={{ maxWidth: '400px' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Link to="/">
                                    <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" style={{ width: '200px' }} />
                                </Link>
                                <h6 className="mb-3 text-uppercase fw-semibold">Please sign in</h6>

                                {error && <div className="alert alert-danger">{error}</div>}

                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Your Email</label>
                                    <input
                                        name="email"
                                        id="email"
                                        type="email"
                                        className={`form-control ${formik.errors.email ? 'is-invalid' : ''}`}
                                        placeholder="example@gmail.com"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.email && formik.touched.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label fw-semibold" htmlFor="loginpass">Password</label>
                                    <input
                                        type="password"
                                        className={`form-control ${formik.errors.password ? 'is-invalid' : ''}`}
                                        id="loginpass"
                                        placeholder="Password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.password && formik.touched.password ? (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    ) : null}
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-label form-check-label text-muted" htmlFor="flexCheckDefault">Remember me</label>
                                        </div>
                                    </div>
                                    <span className="forgot-pass text-muted small mb-0">
                                        <Link to="/forgot-password" className="text-muted">Forgot password ?</Link>
                                    </span>
                                </div>

                                <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                                    {loading ? 'Loading...' : 'Sign in'}
                                </button>

                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted me-2 small">Don't have an account ?</span> <Link to="/signup" className="text-dark fw-semibold small">Sign Up</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
