import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import getBlacklistById from '../common/realBackend/contentManagement/getBlacklistById';
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import blacklistImage1 from '../assets/images/about/ab03.jpg';
import blacklistImage2 from '../assets/images/about/ab04.jpg'

export default function Companies() {
    const [isOpen, setOpen] = useState(false);
    const [blacklist, setBlacklist] = useState(null);

    useEffect(() => {
        const fetchBlacklist = async () => {
            try {
                const data = await getBlacklistById();
                console.log('Blacklist details:', data);
                setBlacklist(data);
            } catch (error) {
                console.error('Error fetching blacklist:', error);
            }
        };

        fetchBlacklist();
    }, []);

    if (!blacklist) {
        return <div>Loading...</div>;
    }

    return (
        <div className="row g-4 align-items-center">
            <div className="col-lg-6 col-md-6 mb-5 order-md-2 order-1">
                <div className="about-right">
                    <div className="position-relative shadow rounded img-one">
                        <img src={blacklistImage1} className="img-fluid rounded" alt="Blacklist Image 1" />

                    </div>
                    <div className="img-two shadow rounded p-2 bg-white">
                        {/* <img src={blacklist.images[1].imageUrl} className="img-fluid rounded" alt="Blacklist Image 2" /> */}
                        <img src={blacklistImage2} className="img-fluid rounded" alt="Blacklist Image 2" /> 

                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-md-1 order-2">
                <div className="section-title mb-4 me-lg-5">
                    <h4 className="title mb-3">{blacklist.titleText}</h4>
                    <p className="text-muted para-desc mb-0">
                        {blacklist.subText}
                    </p>
                </div>

                <div className="mt-4">
                    <Link to="/Aboutus" className="btn btn-link primary text-muted">
                        See More Blacklist  <i className="mdi mdi-arrow-right align-middle"></i>
                    </Link>
                </div>
            </div>
        </div>
    );
}

