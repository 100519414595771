import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthProtectedRoute = () => {
  const authUser = localStorage.getItem('authUser');
  const token = authUser ? JSON.parse(authUser).token : null;

  return token ? <Outlet /> : <Navigate to="/signup" />;
};

export default AuthProtectedRoute;