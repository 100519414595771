import api from '../../../helpers/api_helper';

const createBookmark = async (bookmarkData) => {
  try {
    const response = await api.post('/api/v1/bookmarks', bookmarkData);
    console.log('Bookmark created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating bookmark:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default createBookmark;
