import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import getAllCandidates from '../common/realBackend/candidates/GetAllCandidates';
import getAllDomesticCategories from '../common/realBackend/domesticCategory/GetAllDomesticCategories';
import getHeroSliderById from '../common/realBackend/contentManagement/getHeroSliderById';

import hero1 from '../assets/images/hero1.png';
import Navbar from '../components/navbar';
import AboutUs from '../components/aboutUs';
import Blog from '../components/blog';
import Footer from '../components/footer';
import Companies from '../components/companies';
import AboutTwo from '../components/aboutTwo';
import ScrollTop from '../components/scrollTop';

import { FiMessageCircle } from "../assets/icons/vander";

import heroImg from "../assets/images/hero/bg.jpg";
import FormSelect from '../components/formSelect';
  
export default function Index() {
    const [candidates, setCandidates] = useState([]);
    const [categories, setCategories] = useState([]); // State for categories
    const [heroSlider, setHeroSlider] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const response = await getAllCandidates();
                console.log('Fetched response:', response);

                // Accessing candidates array from the nested data object
                if (response && response.data && response.data.candidates) {
                    setCandidates(response.data.candidates);
                    console.log('Candidates state set:', response.data.candidates);
                } else {
                    console.error('Unexpected API response structure:', response);
                }
            } catch (error) {
                console.error('Error fetching candidates:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await getAllDomesticCategories();
                console.log('Fetched categories:', response);
                // If response is an array directly set it
                if (Array.isArray(response)) {
                    setCategories(response);
                    console.log('Categories state set:', response);
                } else {
                    console.error('Unexpected categories API response structure:', response);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCandidates();
        fetchCategories();
        fetchHeroSlider();
    }, []);

    const fetchHeroSlider = async () => {
        try {
            const response = await getHeroSliderById();
            console.log('Hero slider fetched successfully:', response);
            setHeroSlider(response); // Set heroSlider state with the fetched data
        } catch (error) {
            console.error('Error fetching hero slider:', error);
        }
    };

    const handleViewProfile = (candidate) => {
        console.log("Navigating to candidate profile:", candidate); // Debugging line
        navigate(`/candidate-profile/${candidate.id}`, { state: { candidate } });
      };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" />

            <section className="bg-half-260 d-table w-100" style={{ backgroundImage: `url(${heroSlider ? heroSlider.backgroundImage[0].imageUrl : heroImg})` }}>
                <div className="bg-overlay bg-primary-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="title-heading text-center">
                            <h1 className="heading text-white fw-bold">{heroSlider ? heroSlider.headerText : "Discover & Hire Qualified"} </h1>
                            <p className="para-desc text-white-50 mx-auto mb-0">{heroSlider ? heroSlider.subText : "Find reliable and skilled domestic workers for any household task. Explore job opportunities and career advancements. Trusted by families and households for connecting them with top-notch domestic helpers over the years."}</p>
                                <div className="d-md-flex justify-content-between align-items-center bg-white shadow rounded p-2 mt-4">
                                    <FormSelect />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <AboutUs containerClass="container" />

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center mb-4 pb-2">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title mb-3">Browse by Categories</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Explore all available domestic worker positions. Receive personalized job recommendations. Read reviews on thousands of employers worldwide.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 mt-0">
                        {categories.map((item) => (
                            <div className="col" key={item.id}>
                                <div className="job-category job-category-two rounded shadow bg-light p-3">
                                    <h5 className="mb-1">{item.name}</h5>
                                    <p className="text-muted para mb-2">{item.candidateCount} Domestic Workers Available</p>
                                    <Link to={`/candidates?category=${encodeURIComponent(item.name)}`} className="text-primary fw-medium link">Explore Jobs <i className="mdi mdi-arrow-right"></i></Link>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="text-center">
                                <Link to="/job-categories" className="btn btn-link primary text-muted">See More Categories <i className="mdi mdi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row align-items-end mb-4 pb-2">
                        <div className="col-lg-6 col-md-9">
                            <div className="section-title text-md-start text-center">
                                <h4 className="title mb-3">Explore Candidates</h4>
                                <p className="text-muted para-desc mb-0">Browse all available domestic worker profiles. Get personalized recommendations based on your needs. Read reviews on thousands of candidates worldwide.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-3 d-none d-md-block">
                            <div className="text-md-end">
                                <Link to="/candidates" className="btn btn-link primary text-muted">See More candidates <i className="mdi mdi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4">
                        {candidates.length > 0 ? (
                            candidates.slice(0, 8).map((candidate, index) => ( // Limiting to the first 8 candidates
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                                    <div className="candidate-card position-relative overflow-hidden text-center shadow rounded p-4">
                                        {candidate.rating ? (
                                            <div className="ribbon ribbon-left overflow-hidden">
                                                <span className="text-center d-block bg-warning shadow small h6">
                                                    <i className="mdi mdi-star"></i>
                                                </span>
                                            </div>
                                        ) : ''}
                                        <div className="content">
                                            <img src={candidate.profileImage || hero1} className="avatar avatar-md-md rounded-pill shadow-md" alt="" /> {/* Fallback image if candidate.profileImage is not provided */}
                                            <div className="mt-3">
                                                <Link to={`/candidate-profile/${candidate.id}`} className="title h5 text-dark">{`${candidate.firstName} ${candidate.lastName}`}</Link>
                                                <p className="text-muted mt-1">{candidate.domesticCategory}</p>
                                            </div>

                                            <div className="mt-2 d-flex align-items-center justify-content-between">
                                                <div className="text-center">
                                                    <p className="text-muted fw-medium mb-0">Salary:</p>
                                                    {candidate.salaryRangeMin && candidate.salaryRangeMax ? (
                                                        <p className="mb-0 fw-medium">₦{candidate.salaryRangeMin} - ₦{candidate.salaryRangeMax}</p>
                                                    ) : (
                                                        <p className="mb-0 fw-medium">Not available</p>
                                                    )}
                                                </div>
                                                <div className="text-center">
                                                    <p className="text-muted fw-medium mb-0">Experience:</p>
                                                    <p className="mb-0 fw-medium">{candidate.yearsOfExperience} years</p>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                            <div className="mt-3">
                                            <button
                                                onClick={() => handleViewProfile(candidate)}
                                                className="btn btn-sm btn-primary me-1"
                                            >
                                                View Profile
                                            </button>
                                            <Link to="#" className="btn btn-sm btn-icon btn-soft-primary">
                                                <FiMessageCircle className="icons" />
                                            </Link>
                                            </div>
                                            <Link to="" className="like">
                                            <i className="mdi mdi-heart align-middle fs-4"></i>
                                            </Link>
                                            </div>

                                            <Link to="" className="like"><i className="mdi mdi-heart align-middle fs-4"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 text-center">
                                <p>No candidates found.</p>
                            </div>
                        )}

                        <div className="col-12 d-md-none d-block">
                            <div className="text-center mt-4">
                                <Link to="/candidates" className="btn btn-link primary text-muted">See More candidates <i className="mdi mdi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container mt-100 mt-60">
                <Companies />
            </div>

            <div className="container mt-100 mt-60">
                <AboutTwo />
            </div>

            <section className="section pt-0">
                <div className="container mt-100 mt-60">
                    <div className="row align-items-end mb-4 pb-2">
                        <div className="col-lg-6 col-md-9">
                            <div className="section-title text-md-start text-center">
                                <h4 className="title mb-3">Explore Articles</h4>
                                <p className="text-muted para-desc mb-0">Read the latest articles, tips, and advice on hiring and managing domestic workers. Stay informed and make well-informed decisions for your household.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-3 d-none d-md-block">
                            <div className="text-md-end">
                                <Link to="/blogs" className="btn btn-link primary text-muted">See More Articles <i className="mdi mdi-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                    <Blog />
                </div>
            </section>

            <Footer />
            <ScrollTop />
        </>
    );
}


