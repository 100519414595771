import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import client from "../assets/images/team/01.jpg";
import { LuSearch, FiUser, FiSettings, FiLock, FiLogOut, FiBookmark, FiList, FiMessageSquare } from "../assets/icons/vander";

export default function NavbarDark() {
    const [isOpen, setMenu] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [search, setSearch] = useState(false);
    const [cartitem, setCartitem] = useState(false);
    const [manu, setManu] = useState('');
    const [token, setToken] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        setManu(current);
    }, [location.pathname]);

    useEffect(() => {

        // Get token and profileImageUrl from localStorage
        const authUser = localStorage.getItem("authUser");
        if (authUser) {
            const parsedUser = JSON.parse(authUser);
            setToken(parsedUser.token);
            setProfileImageUrl(parsedUser.data.profileImageUrl);
        }
    }, []);

    const handleDropdownClick = (event) => {
        event.preventDefault();
        const target = event.target.getAttribute("href");

        if (target === "/logout") {
            handleLogout();
        } else {
            setCartitem(false);
            if (target !== "") {
                navigate(target);
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setToken(null);
        navigate("/login");
    };

    const closeDropdowns = (event) => {
        if (search && !event.target.closest('.dropdown-toggle')) {
            setSearch(false);
        }
        if (cartitem && !event.target.closest('.dropdown-primary')) {
            setCartitem(false);
        }
    };
    
    useEffect(() => {
        function scrollHandler() {
            setScroll(window.scrollY > 50);
        }
        window.addEventListener('scroll', scrollHandler);

        let searchModal = () => { setSearch(false); };
        document.addEventListener('mousedown', searchModal);

        let cartModal = () => { setCartitem(false); };
        document.addEventListener('mousedown', cartModal);

        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('mousedown', searchModal);
            document.removeEventListener('mousedown', cartModal);
        };

        document.addEventListener('mousedown', closeDropdowns);
    }, []);

    const toggleMenu = () => {
        setMenu(!isOpen);
    };

    const toggleSubmenu = (event) => {
        event.preventDefault();
        const target = event.currentTarget;
        const submenu = target.nextElementSibling;
        if (submenu) {
            submenu.classList.toggle('open');
        }
    };

    const renderLinkOrLogin = (path, label) => {
        return token ? (
            <Link to={path} className="sub-menu-item">{label}</Link>
        ) : (
            <Link to="/login" className="sub-menu-item">{label}</Link>
        );
    };

    return (
        <header id="topnav" className={`${scroll ? 'nav-sticky' : ''} defaultscroll sticky`}>
            <div className="container">
                <Link className="logo" to="/">
                    <img src={logoDark} className="logo-light-mode" alt="logo" style={{ maxWidth: '100px', height: 'auto' }}/>
                    <img src={logoLight} className="logo-dark-mode" alt="logo" style={{ maxWidth: '100px', height: 'auto' }}/>
                </Link>
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className={`lines ${isOpen ? 'active' : ''}`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className="buy-button list-inline mb-0">
                    {token ? (
                        <>

                            <li className="list-inline-item ps-1 mb-0">
                                <div className="dropdown dropdown-primary">
                                    <button type="button" onClick={() => setCartitem(!cartitem)} className="dropdown-toggle btn btn-sm btn-icon btn-pills btn-primary"
                                    >
                                        <div className="d-flex align-items-center justify-content-center rounded-circle overflow-hidden" style={{ width: '40px', height: '40px' }}>
                                            <img src={profileImageUrl} className="img-fluid w-100 h-100" alt="Profile" />
                                        </div>
                                    </button>
                                    <div style={{ display: cartitem ? 'block' : 'none' }}>
                                        <div
                                            className="dropdown-menu dd-menu bg-white rounded shadow border-0 mt-3 show"
                                            style={{
                                                right: '0',
                                                left: 'auto',
                                                position: 'absolute',
                                                minWidth: '240px',
                                            }}
                                        >

                                            <Link to="/job-list-one" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}>
                                                <FiBookmark className="fea icon-sm me-2 align-middle" />Bookmarked Candidates
                                            </Link>
                                            <Link to="/job-list-two" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}>
                                                <FiUser className="fea icon-sm me-2 align-middle" /> Manage Candidates
                                            </Link>
                                            <Link to="/job-list-two" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}><FiList className="fea icon-sm me-2 align-middle" />Job List</Link>
                                            <Link to="/chat" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}><FiMessageSquare className="fea icon-sm me-2 align-middle" />Chat</Link>
                                            <Link to="/user-profile-setting" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}><FiSettings className="fea icon-sm me-2 align-middle" />Settings</Link>
                                            <div className="dropdown-divider border-top"></div>
                                            <Link to="/login" className="dropdown-item fw-medium fs-6" onClick={handleDropdownClick}><FiLogOut className="fea icon-sm me-2 align-middle" />Logout</Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="list-inline-item ps-1 mb-0">
                                <Link to="/login" className="btn btn-sm btn-primary">Login</Link>
                            </li>
                            <li className="list-inline-item ps-1 mb-0">
                                <Link to="/signup" className="btn btn-sm btn-secondary">Sign Up</Link>
                            </li>
                        </>
                    )}
                </ul>

                <div id="navigation" className={isOpen ? 'open' : ''}>
                    <ul className="navigation-menu nav-right">
                        <li className={manu === "aboutus" ? "active" : ""}><Link to="/aboutus" className="sub-menu-item">About Us</Link></li>
                        <li className={`${["terms", "privacy"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="#" onClick={toggleSubmenu}>Utility</Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${["terms", "privacy"].includes(manu) ? 'open' : ''}`}>
                                <li className={manu === "terms" ? "active" : ""}><Link to="/terms" className="sub-menu-item">Terms of Services</Link></li>
                                <li className={manu === "privacy" ? "active" : ""}><Link to="/privacy" className="sub-menu-item">Privacy Policy</Link></li>
                            </ul>
                        </li>
                        <li className={manu === "blacklist" ? "active" : ""}><Link to="/blacklist" className="sub-menu-item">Blacklist</Link></li>
                        <li className={`${["candidates", "candidate-profile", "candidate-profile-setting"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="#" onClick={toggleSubmenu}>Listing</Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${["candidates", "candidate-profile", "candidate-profile-setting"].includes(manu) ? 'open' : ''}`}>
                                <li className={manu === "candidates" ? "active" : ""}><Link to="/candidates" className="sub-menu-item">Candidates</Link></li>
                                <li className={manu === "candidates" ? "active" : ""}><Link to="/job-categories" className="sub-menu-item">Categories</Link></li>
                                <li className={manu === "create-domestic-worker" ? "active" : ""}>{renderLinkOrLogin("/create-domestic-worker", "Create Candidate")}</li>
                                <li className={manu === "job-apply" ? "active" : ""}>{renderLinkOrLogin("/job-apply", "Create Job Vacancy")}</li>
                                <li className={manu === "blacklist" ? "active" : ""}>{renderLinkOrLogin("/blacklist_candidate", "Blacklist Candidate")}</li>
                            </ul>
                        </li>

                        <li className={manu === "pricing" ? "active" : ""}><Link to="/pricing" className="sub-menu-item">Pricing</Link></li>
                    </ul>
                </div>
            </div>
        </header>
    );

}






      