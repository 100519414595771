import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoDark from "../assets/images/logo-dark.png";
import logoWhite from "../assets/images/logo-white.png";
import logoLight from "../assets/images/logo-light.png";
import client from "../assets/images/team/01.jpg";
import { LuSearch, FiUser, FiSettings, FiLock, FiLogOut, FiBookmark, FiList, FiMessageSquare } from "../assets/icons/vander";

export default function Navbar({ navClass, navLight }) {
    const [isOpen, setIsOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [search, setSearch] = useState(false);
    const [cartitem, setCartitem] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const [token, setToken] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        // Update active menu based on current URL path
        const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        setActiveMenu(current);
    }, [location.pathname]);

    useEffect(() => {
        const scrollHandler = () => setScroll(window.scrollY > 50);
        window.addEventListener('scroll', scrollHandler);

        // Add event listener for mousedown to close dropdowns when clicked outside
        document.addEventListener('mousedown', closeDropdowns);

        // Scroll to top on mount
        window.scrollTo(0, 0);

        // Get token and profileImageUrl from localStorage
        const authUser = localStorage.getItem("authUser");
        if (authUser) {
            const parsedUser = JSON.parse(authUser);
            setToken(parsedUser.token);
            setProfileImageUrl(parsedUser.data.profileImageUrl);
        }

        return () => {
            // Cleanup event listeners on component unmount
            window.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('mousedown', closeDropdowns);
        };

    }, []);

    // Function to close dropdowns when clicked outside
    const closeDropdowns = (event) => {
        if (search && !event.target.closest('.dropdown-toggle')) {
            setSearch(false);
        }
        if (cartitem && !event.target.closest('.dropdown-primary')) {
            setCartitem(false);
        }
    };

    // Function to handle clicks on dropdown links
    const handleDropdownClick = (event) => {
        event.preventDefault();
        setCartitem(false);
        const target = event.target.getAttribute("href");
        if (target === "/logout") {
            handleLogout();
        } else if (target) {
            window.location.href = target;
        }
    };

    // Function to handle logout
    const handleLogout = () => {
        localStorage.removeItem("authUser");
        setToken(null);
        navigate("/login");
    };

    // Function to toggle the mobile menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        const navigationElement = document.getElementById("navigation");
        if (navigationElement) {
            if (isOpen) {
                navigationElement.classList.remove('open');
            } else {
                navigationElement.classList.add('open');
            }
        }
    };

    const renderLinkOrLogin = (path, label) => {
        return token ? (
            <Link to={path} className="sub-menu-item">{label}</Link>
        ) : (
            <Link to="/login" className="sub-menu-item">{label}</Link>
        );
    };

    return (
        <header id="topnav" className={`${scroll ? 'nav-sticky' : ''} ${navClass}`}>
            <div className="container">
            {navLight ?
                    <Link className="logo" to="/">
                        <span className="logo-light-mode">
                            <img src={logoDark} className="l-dark" alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                            <img src={logoLight} className="l-light" alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                        </span>
                        <img src={logoLight} className="logo-dark-mode" alt="" />
                    </Link> :
                    <Link className="logo" to="/">
                        <span className="logo-light-mode">
                            <img src={logoDark} className="l-dark" alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                            <img src={logoWhite} className="l-light" alt="" style={{ maxWidth: '100px', height: 'auto' }} />
                        </span>
                        <img src={logoWhite} className="logo-dark-mode" alt="" />
                    </Link>
                }
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to='#' className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div id="navigation" className={isOpen ? "open" : ""}>
                    <ul className="navigation-menu nav-right nav-light">

                        <li className={activeMenu === "pricing" ? "active" : ""}><Link to="/" className="sub-menu-item">Home</Link></li>

                        <li className={activeMenu === "pricing" ? "active" : ""}><Link to="/aboutus" className="sub-menu-item">About Us</Link></li>

                        <li className={activeMenu === "pricing" ? "active" : ""}><Link to="/helpcenter-faqs" className="sub-menu-item">FAQ</Link></li>

                        <li className={activeMenu === "pricing" ? "active" : ""}><Link to="/pricing" className="sub-menu-item">Pricing</Link></li>

                        <li className={activeMenu === "pricing" ? "active" : ""}><Link to="/contactus" className="sub-menu-item">Contact Us</Link></li>

                        <ul className="buy-button list-inline mb-0">
                            <>
                                <li className="list-inline-item ps-1 mb-0">
                                    <Link to="https://job.precheckhire.com/#/signup" className="btn btn-sm btn-primary">JOB SEEKER</Link>
                                </li>
                                <li className="list-inline-item ps-1 mb-0">
                                    <Link to="https://employer.precheckhire.com/#/signup" className="btn btn-sm btn-secondary">EMPLOYER</Link>
                                </li>
                                <li className="list-inline-item ps-1 mb-0">
                                    <Link to="https://blacklist.precheckhire.com/#/signup" className="btn btn-sm btn-secondary">BLACKLIST</Link>
                                </li>
                            </>
                        </ul>
                        
                        
                        
                    </ul>
                </div>
            </div>
        </header>
    );
}