import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import bg1 from "../assets/images/hero/bg.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import getAllCandidatesWithBlacklistThreshold from "../common/realBackend/candidates/GetAllCandidatesWithBlacklistThreshold";
import getAllDomesticCategories from "../common/realBackend/domesticCategory/GetAllDomesticCategories";
import getNigeriaLGAbyStateName from "../common/realBackend/users/GetNigeriaLGAbyStateName";
import getNigeriaState from "../common/realBackend/users/GetNigeriaState";
import { FiMessageCircle } from "react-icons/fi";
import hero1 from '../assets/images/hero1.png';

// Mock backend function to check subscription status
const checkSubscriptionStatus = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true); // Change this to `true` to simulate a subscribed user
        }, 500);
    });
};

export default function Blacklist() {
  const [searchTerm, setSearchTerm] = useState("");
  const [jobTypeFilter, setJobTypeFilter] = useState("All");
  const [allCandidates, setAllCandidates] = useState([]);
  const [stateFilter, setStateFilter] = useState("All");
  const [lgaFilter, setLgaFilter] = useState("All");
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [categories, setCategories] = useState([]); // Initial state as an empty array
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [categoryMap, setCategoryMap] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
        try {
          const [candidatesResponse, categoriesResponse] = await Promise.all([
            getAllCandidatesWithBlacklistThreshold(),
            getAllDomesticCategories()
          ]);
        
          const categoryMap = categoriesResponse.reduce((map, category) => {
            map[category.id] = category.name;
            return map;
          }, {});
  
          setCategoryMap(categoryMap);

        const filteredCandidates = candidatesResponse.data.candidates
          .filter(candidate => candidate.blacklistCount >= 5 )
          .map(candidate => {
            // Extract the first domestic category name if available
            const firstCategoryName = (candidate.DomesticCategories || [])
              .map(category => category.name)
              .find(name => name) || "Category not specified";

            return {
              id: candidate.id,
              name: candidate.fullName,
              about: candidate.about,
              phone: candidate.phone,
              whatsapp: candidate.whatsapp,
              post: firstCategoryName, // List of category names here
              experience: candidate.yearsOfExperience,
              location: candidate.location,
              resume: candidate.resume,
              isAvailable: candidate.isAvailable,
              rating: candidate.rating,
              userId: candidate.userId,
              blacklistCount: candidate.blacklistCount,
              createdAt: candidate.createdAt,
              updatedAt: candidate.updatedAt,
              education: candidate.education,
              experienceDetails: candidate.experience,
              reviews: candidate.reviews,
              profileImage: candidate.profileImage,
              backgroundImage: candidate.backgroundImage,
              salaryRangeMin: candidate.salaryRangeMin,
              salaryRangeMax: candidate.salaryRangeMax,
              languagesSpoken: candidate.languagesSpoken
            };
          });

        setAllCandidates(filteredCandidates);
        console.log("domestic",filteredCandidates.domesticCategories.name)
        setCategories(Array.isArray(categoriesResponse) ? categoriesResponse : []);// Ensure categories is an array

      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchData();

    getNigeriaState()
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error("Error fetching states:", error);
      });

  }, []);

  useEffect(() => {
    if (stateFilter !== "All") {
      getNigeriaLGAbyStateName(stateFilter)
        .then(response => {
          setLgas(response.data.lgas);
        })
        .catch(error => {
          console.error("Error fetching LGAs:", error);
        });
    } else {
      setLgas([]);
    }
  }, [stateFilter]);

  // Parse query parameters
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setSearchTerm(query.get("keyword") || "");
    setStateFilter(query.get("location") || "All");
    setJobTypeFilter(query.get("category") || "All");
  }, [location.search]);

  const formatSalary = (amount) => {
    if (amount === null || amount === undefined) return 'Not Available';
    const number = parseInt(amount);
    return number >= 1000 ? `₦${(number / 1000).toFixed(0)}K` : `₦${number.toLocaleString()}`;
  };

  const filteredCandidates = allCandidates.filter((candidate) => {
    const [state1, lga1] = candidate.location.split(',').map(part => part.trim());
    // Debugging
    console.log("candidate.post:", candidate.post);
    return (
      candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (stateFilter === "All" || state1 === stateFilter) &&
    (lgaFilter === "All" || lga1 === lgaFilter) &&
    (jobTypeFilter === "All" || candidate.post === jobTypeFilter)
    );
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStateFilterChange = (event) => {
    setStateFilter(event.target.value);
    setLgaFilter("All");
  };

  const handleLgaFilterChange = (event) => {
    setLgaFilter(event.target.value);
  };

  const handleJobTypeFilterChange = (event) => {
    setJobTypeFilter(event.target.value);
  };

  useEffect(() => {
    checkSubscriptionStatus().then(status => setIsSubscribed(status));
}, []);

if (isSubscribed === null) {
    return <div>Loading...</div>;
}

  const handleViewProfile = (candidate) => {
    console.log("Navigating to candidate profile:", candidate); // Debugging line
    navigate(`/candidate-profile/${candidate.id}`, { state: { candidate } });
  };

  return (
    <>
        <Navbar navClass="defaultscroll sticky" navLight={true} />

        <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Blacklist</h5>
                        </div>
                    </div>
                </div>

                <div className="position-middle-bottom">
                    <nav aria-label="breadcrumb" className="d-block">
                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                            <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Blacklist</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                {isSubscribed ? (
                    <div className="row g-4">
                        <div className="container">
            <div className="row g-4">
                <div className="col-md-3">
                <div className="mb-3">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Search candidates..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                </div>
                </div>
                <div className="col-md-3">
                <div className="mb-3">
                    <select
                    className="form-select"
                    value={stateFilter}
                    onChange={handleStateFilterChange}
                    >
                    <option value="All">All States</option>
                    {states.map((state) => (
                        <option key={state} value={state}>{state}</option>
                    ))}
                    </select>
                </div>
                </div>
                <div className="col-md-3">
                <div className="mb-3">
                    <select
                    className="form-select"
                    value={lgaFilter}
                    onChange={handleLgaFilterChange}
                    disabled={stateFilter === "All"}
                    >
                    <option value="All">All LGAs</option>
                    {lgas.map((lga, index) => (
                        <option key={index} value={lga}>{lga}</option>
                    ))}
                    </select>
                </div>
                </div>
                <div className="col-md-3">
              <div className="mb-3">
                <select
                  className="form-select"
                  value={jobTypeFilter}
                  onChange={handleJobTypeFilterChange}
                >
                  <option value="All">All Job Types</option>
                  {Object.entries(categoryMap).map(([id, name]) => (
                    <option key={id} value={name}>{name}</option>
                  ))}
                </select>
              </div>
            </div>
            </div>
           </div>
                        {filteredCandidates.map((item, index) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                                <div className="candidate-card position-relative overflow-hidden text-center shadow rounded p-4">
                                    {item.rate === true ?
                                        <div className="ribbon ribbon-left overflow-hidden"><span className="text-center d-block bg-warning shadow small h6"><i className="mdi mdi-star"></i></span></div> : ''
                                    }
                                    <div className="content">
                                        <img src={item.profileImage || hero1} className="avatar avatar-md-md rounded-pill shadow-md" alt="" />

                                        <div className="mt-3">
                                            <Link to={`/candidate-profile/${item.id}`} className="title h5 text-dark">{item.name}</Link>
                                        </div>

                                        <div className="mt-2 d-flex align-items-center justify-content-between">
                                            <div className="text-center">
                                                <p className="text-muted fw-medium mb-0">Salary:</p>
                                                <p className="mb-0 fw-medium">{formatSalary(item.salaryRangeMin)} - {formatSalary(item.salaryRangeMax)}</p>
                                            </div>

                                            <div className="text-center">
                                                <p className="text-muted fw-medium mb-0">Experience:</p>
                                                <p className="mb-0 fw-medium">{item.experience} Years</p>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                        <button
                                            onClick={() => handleViewProfile(item)}
                                            className="btn btn-sm btn-primary me-1"
                                        >
                                            View Profile
                                        </button>
                                        <Link to="#" className="btn btn-sm btn-icon btn-soft-primary">
                                            <FiMessageCircle className="icons" />
                                        </Link>
                                        </div>

                                        <Link to="" className="like"><i className="mdi mdi-heart align-middle fs-4"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">
                        <h3 className="mb-3">You do not have a subscription</h3>
                        <p>Subscribe to access the blacklist and other premium features.</p>
                        <Link to="/pricing" className="btn btn-primary">Subscribe Now</Link>
                    </div>
                )}
            </div>

            {isSubscribed && (
                <div className="row">
                    <div className="col-12 mt-4 pt-2">
                        <ul className="pagination justify-content-center mb-0">
                            <li className="page-item">
                                <Link className="page-link" to="#" aria-label="Previous">
                                    <span aria-hidden="true"><i className="mdi mdi-chevron-left fs-6"></i></span>
                                </Link>
                            </li>
                            <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                            <li className="page-item active"><Link className="page-link" to="#">2</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                            <li className="page-item">
                                <Link className="page-link" to="#" aria-label="Next">
                                    <span aria-hidden="true"><i className="mdi mdi-chevron-right fs-6"></i></span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </section>

        <Footer top={true} />
        <ScrollTop />
    </>
);
}
