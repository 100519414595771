import api from '../../../helpers/api_helper';

const getAllCandidatesWithBlacklistThreshold = async () => {
  try {
    const response = await api.get('/api/v1/candidates/getAllCandidatesWithBlacklistThreshold');
    console.log('Candidates with blacklist threshold fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching candidates with blacklist threshold:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getAllCandidatesWithBlacklistThreshold;
