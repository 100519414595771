import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import getHowToGetStartedById from "../common/realBackend/contentManagement/getHowToGetStartedById";
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

export default function Faq() {
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await getHowToGetStartedById();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    if (!data) {
        return <div>Loading...</div>;
    }

    const { images, youtubeLink } = data;

    return (
        <div className="row g-4 align-items-center">
            <div className="col-lg-6 col-md-6 mb-5">
                <div className="about-left">
                    <div className="position-relative shadow rounded img-one">
                        <img src={images[0]?.imageUrl} className="img-fluid rounded" alt="About Us 1"/>
                    </div>

                    <div className="img-two shadow rounded p-2 bg-white">
                        <img src={images[1]?.imageUrl} className="img-fluid rounded" alt="About Us 2"/>

                        <div className="position-absolute top-0 start-50 translate-middle">
                            <Link to="#!" onClick={() => setOpen(true)} className="avatar avatar-md-md rounded-pill shadow card d-flex justify-content-center align-items-center lightbox">
                                <i className="mdi mdi-play mdi-24px text-primary"></i>
                            </Link>
                        </div>
                        <ModalVideo
                            channel="youtube"
                            youtube={{ mute: 0, autoplay: 0 }}
                            isOpen={isOpen}
                            videoId={youtubeLink.split('v=')[1]}
                            onClose={() => setOpen(false)} 
                        />
                    </div>
                </div>
            </div>

            <div className="col-lg-6 col-md-6">
                <div className="section-title mb-4 ms-lg-3">
                    <h4 className="title mb-3">Frequently Asked Questions</h4>
                    <p className="text-muted para-desc mb-0">
                        Search for available domestic workers at Precheck Hire. Review candidate profiles and choose the best fit for your needs.
                    </p>
                
                    <div className="accordion mt-4 pt-2" id="buyingquestion">
                        <div className="accordion-item rounded mt-2">
                            <h2 className="accordion-header" id="headingOne">
                                <button 
                                    className={`accordion-button border-0 bg-light ${activeIndex === 0 ? '' : 'collapsed'}`}
                                    onClick={() => toggleAccordion(0)}
                                >
                                    What is Precheck Hire?
                                </button>
                            </h2>
                            <div id="collapseOne" className={`accordion-collapse border-0 collapse ${activeIndex === 0 ? 'show' : ''}`}>
                                <div className="accordion-body text-muted">
                                    Precheck Hire is a platform to find, hire, and review domestic workers.
                                </div>
                            </div>
                        </div>
                        {/* Additional accordion items go here */}
                    </div>
                </div>
            </div>
        </div>
    );
}
