import api from '../../../helpers/api_helper';

const userProfileUpdate = async (formData) => {
  try {
    const response = await api.patch('/api/v1/users/updateMe', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('User profile updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default userProfileUpdate;
