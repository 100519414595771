import api from '../../../helpers/api_helper';

const getUserMessages = async () => {
  try {
    const response = await api.get('/api/v1/notifications/messages');
    console.log('Messages fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching messages:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getUserMessages;
