import api from '../../../helpers/api_helper';

// Function to get all candidates
const getAllCandidates = async () => {
  try {
    const response = await api.get('/api/v1/candidates/getAllCandidates');
    console.log('Candidates fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error('Error fetching candidates:', error.response.data);
    } else if (error.request) {
      // No response was received
      console.error('Error fetching candidates: No response received from server');
    } else {
      // Something went wrong in setting up the request
      console.error('Error fetching candidates:', error.message);
    }
    throw error;
  }
};

export default getAllCandidates;
