import api from '../../../helpers/api_helper';

const getUserProfile = async () => {
  try {
    const response = await api.get('/api/v1/users/me');
    console.log('User profile fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getUserProfile;
