import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import bg1 from "../assets/images/hero/bg5.jpg";
import NavbarDark from "../components/navbarDark";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import updateCandidate from '../common/realBackend/candidates/UpdateCandidate';
import getCandidateDetails from '../common/realBackend/candidates/GetCandidateDetails';
import getAllDomesticCategories from '../common/realBackend/domesticCategory/GetAllDomesticCategories';
import getNigeriaLGAbyStateName from '../common/realBackend/users/GetNigeriaLGAbyStateName';
import getNigeriaState from '../common/realBackend/users/GetNigeriaState';
import { FiCamera } from "../assets/icons/vander";

export default function CandidateProfileSetting() {
    const [candidate, setCandidate] = useState(null);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [feedback, setFeedback] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchCandidate = async () => {
            try {
                const { data } = await getCandidateDetails(id);
                setCandidate(data.candidate);

                // Extract initial state and LGA from candidate location
                const [initialState, initialLGA] = data.candidate.location ? data.candidate.location.split(',') : ['', ''];
                setSelectedState(initialState.trim());
                setLgas(await fetchLgasByState(initialState.trim())); // Fetch LGAs for the initial state
                setProfileImage(data.candidate.profileImage);
                setBackgroundImage(data.candidate.backgroundImage);
            } catch (error) {
                console.error('Error fetching candidate details:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await getAllDomesticCategories();
                if (Array.isArray(response)) {
                    setCategories(response);
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        const fetchStates = async () => {
            try {
                const response = await getNigeriaState();
                if (Array.isArray(response.data)) {
                    setStates(response.data);
                }
            } catch (error) {
                console.error('Error fetching States:', error);
            }
        };

        fetchCandidate();
        fetchCategories();
        fetchStates();
    }, [id]);

    useEffect(() => {
        const fetchLgas = async () => {
            if (selectedState) {
                try {
                    const response = await getNigeriaLGAbyStateName(selectedState);
                    if (Array.isArray(response.data.lgas)) {
                        setLgas(response.data.lgas);
                    }
                } catch (error) {
                    console.error('Error fetching LGAs:', error);
                }
            } else {
                setLgas([]); // Clear LGAs if no state is selected
            }
        };

        fetchLgas();
    }, [selectedState]);

    const fetchLgasByState = async (state) => {
        try {
            const response = await getNigeriaLGAbyStateName(state);
            return Array.isArray(response.data.lgas) ? response.data.lgas : [];
        } catch (error) {
            console.error('Error fetching LGAs:', error);
            return [];
        }
    };

    const handleImageUpload = (e, imageType) => {
        const file = e.target.files[0];
        if (!file) return;  // This is correctly inside the function
    
        const reader = new FileReader();
        reader.onloadend = () => {
            if (imageType === 'profile') {
                setProfileImage(reader.result);
            } else if (imageType === 'background') {
                setBackgroundImage(reader.result);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (values) => {
        const location = `${values.state || ''}, ${values.lga || ''}`;
        const formData = new FormData();
    
        // Add fields to formData
        formData.append('firstName', values.fullName.split(' ')[0] || '');
        formData.append('middleName', values.fullName.split(' ')[1] || '');
        formData.append('lastName', values.fullName.split(' ')[2] || '');
        formData.append('about', values.about || '');
        formData.append('phone', values.phone || '');
        formData.append('whatsapp', values.whatsapp || '');
        formData.append('yearsOfExperience', values.yearsOfExperience || '');
        formData.append('location', location || '');
        formData.append('salaryRangeMin', values.salaryRangeMin || '');
        formData.append('salaryRangeMax', values.salaryRangeMax || '');
        
        // Convert education to JSON string
        const educationData = {
            degree: values.highestDegree || '',
            institution: values.institution || '',
            endYear: values.graduationYear || ''
        };

        formData.append('education', JSON.stringify(educationData));
    
        formData.append('isAvailable', values.isAvailable ? 'true' : 'false'); // Ensure boolean is a string
    
        const categoryId = parseInt(values.category.trim(), 10);
            if (!isNaN(categoryId)) {
                const categoryIdsArray = [categoryId];
                const categoryIdsString = JSON.stringify(categoryIdsArray); // Serialize array to JSON string
                formData.append('domesticCategoryIds', categoryIdsString); // Send as a string
            } else {
                console.error('Invalid category ID:', values.category);
            }
    
        // Convert languagesSpoken to array and serialize as JSON
        const languagesArray = values.languagesSpoken.split(',').map(lang => lang.trim()).filter(Boolean);
        formData.append('languagesSpoken', JSON.stringify(languagesArray));
    
        // Append files if present
        if (values.resume) formData.append('resume', values.resume);
        if (profileImage) formData.append('profileImage', profileImage);
        if (backgroundImage) formData.append('backgroundImage', backgroundImage);
    
        // Debug: log all FormData entries
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
    
        try {
            await updateCandidate(id, formData);
            setFeedback({ message: 'Candidate updated successfully!', type: 'success' });
        } catch (error) {
            // Improved error handling
            console.error('Error updating candidate:', error.response ? error.response.data : error.message);
            setFeedback({ message: 'Error updating candidate!', type: 'error' });
        }
    };
    
    if (!candidate) {
        return <div>Loading...</div>;
    }
    
    const [initialState, initialLGA] = candidate.location ? candidate.location.split(',') : ['', ''];
    
    

    return (
        <>
            <NavbarDark />
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="position-relative">
                                <div className="candidate-cover">
                                <div className="profile-banner relative text-transparent">
                                    <input 
                                        type="file" 
                                        id="pro-banner" 
                                        style={{ display: 'none' }} 
                                        onChange={(e) => handleImageUpload(e, 'background')} 
                                    />
                                    <div className="relative shrink-0">
                                        <img 
                                            src={backgroundImage || bg1} 
                                            className="rounded shadow" 
                                            id="profile-banner" 
                                            alt="" 
                                        />
                                        <label className="profile-image-label" htmlFor="pro-banner">
                                            <span className="btn btn-icon btn-sm btn-pills btn-primary">
                                                <FiCamera className="icons" />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                </div>
                                <div className="candidate-profile d-flex align-items-end mx-2">
                                    <div className="position-relative">
                                        <input 
                                            type="file" 
                                            id="pro-img" 
                                            style={{ display: 'none' }} 
                                            onChange={(e) => handleImageUpload(e, 'profile')} 
                                        />
                                        <div className="position-relative d-inline-block">
                                            {profileImage && (
                                                <img 
                                                    src={profileImage} 
                                                    className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm" 
                                                    id="profile-image" 
                                                    alt="" 
                                                />
                                            )}
                                            <label className="icons position-absolute bottom-0 end-0" htmlFor="pro-img">
                                                <span className="btn btn-icon btn-sm btn-pills btn-primary">
                                                    <FiCamera className="icons" />
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-2">
                                        <h5 className="mb-0">{candidate.fullName}</h5>
                                        <p className="text-muted mb-0">{candidate.location}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="rounded shadow p-4">
                            <Formik
                                            initialValues={{
                                                fullName: candidate.fullName || '',
                                                phone: candidate.phone || '',
                                                whatsapp: candidate.whatsapp || '',
                                                yearsOfExperience: candidate.yearsOfExperience || '',
                                                state: initialState.trim() || '',
                                                lga: initialLGA.trim() || '',
                                                about: candidate.about || '',
                                                resume: null,
                                                isAvailable: candidate.isAvailable || false,
                                                salaryRangeMin: candidate.salaryRangeMin || '',
                                                salaryRangeMax: candidate.salaryRangeMax || '',
                                                languagesSpoken: candidate.languagesSpoken.join(', ') || '',
                                                category: candidate.DomesticCategories.map(cat => cat.id).join(', ') || '',
                                                highestDegree: candidate.education.length > 0 ? candidate.education[0].degree : '',
                                                institution: candidate.education.length > 0 ? candidate.education[0].institution : '',
                                                graduationYear: candidate.education.length > 0 ? candidate.education[0].endYear : '',// Ensure this field is correct
                                            }}
                                            onSubmit={handleSubmit}
                                        >
                                    {({ setFieldValue }) => (
                                        <Form encType="multipart/form-data"> 
                                            <h5>Personal Details :</h5>
                                            <div className="row mt-4">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Full Name:</label>
                                                        <Field name="fullName" type="text" className="form-control" placeholder="Full Name" />
                                                        <ErrorMessage name="fullName" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Job Category:</label>
                                                        <Field as="select" name="category" className="form-select">
                                                            <option value="">Select Category</option>
                                                            {categories.map(category => (
                                                                <option key={category.id} value={category.id}>{category.name}</option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="category" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">
                                                            About Domestic Worker: <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <Field
                                                            as="textarea"
                                                            name="about"
                                                            id="about"
                                                            rows="4"
                                                            className="form-control"
                                                            placeholder="Tell us something about the worker"
                                                            required
                                                            aria-required="true"
                                                        />
                                                        <ErrorMessage name="about" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Salary Range:</label>
                                                        <div className="input-group">
                                                        <Field 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="salaryRangeMin" 
                                                            name="salaryRangeMin"
                                                        />
                                                            <Field 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="salaryRangeMax" 
                                                            name="salaryRangeMax"
                                                        />
                                                        </div>
                                                        <ErrorMessage name="salaryRangeMin" component="div" className="text-danger" />
                                                        <ErrorMessage name="salaryRangeMax" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="formFile" className="form-label fw-semibold">Resume:</label>
                                                        <input className="form-control" type="file" id="formFile" onChange={(e) => setFieldValue('resume', e.currentTarget.files[0])} />
                                                        <ErrorMessage name="resume" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <h5 className="mb-3">Contact:</h5>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Phone:</label>
                                                        <Field name="phone" type="text" className="form-control" placeholder="Phone Number" />
                                                        <ErrorMessage name="phone" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">WhatsApp:</label>
                                                        <Field name="whatsapp" type="text" className="form-control" placeholder="WhatsApp Number" />
                                                        <ErrorMessage name="whatsapp" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="state" className="form-label fw-semibold">State:</label>
                                                        <Field as="select" name="state" className="form-select" required
                                                            onChange={(e) => {
                                                                const stateName = e.target.value;
                                                                setFieldValue("state", stateName);
                                                                setSelectedState(stateName);
                                                            }}>
                                                            <option value="">Select State</option>
                                                            {states.map(state => (
                                                                <option key={state} value={state}>{state}</option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="state" component="div" className="text-danger" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="lga" className="form-label fw-semibold">LGA:</label>
                                                        <Field as="select" name="lga" className="form-select" required>
                                                            {lgas.map((lga, index) => (
                                                                <option key={index} value={lga}>{lga}</option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="lga" component="div" className="text-danger" />
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                <Field 
                                                    type="checkbox" 
                                                    id="isAvailable" 
                                                    name="isAvailable"
                                                />
                                                <label htmlFor="isAvailable" className="form-label ms-2">Available for Work</label>
                                            </div>
                                                <h5 className="mb-3">Education:</h5>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="highestDegree" className="form-label fw-semibold">Highest Degree Obtained:</label>
                                                    <Field 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="highestDegree" 
                                                    name="highestDegree"
                                                    />
                                                    <ErrorMessage name="highestDegree" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="institution" className="form-label fw-semibold">Institution:</label>
                                                    <Field 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="institution" 
                                                        name="institution"
                                                    />
                                                    <ErrorMessage name="institution" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="graduationYear" className="form-label fw-semibold">Graduation Year:</label>
                                                    <Field 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="graduationYear" 
                                                        name="graduationYear"
                                                    />
                                                    <ErrorMessage name="graduationYear" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <h5 className="mb-3">Experience:</h5>
                                            <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Years of Experience:</label>
                                                        <Field name="yearsOfExperience" type="number" className="form-control" placeholder="Years of Experience" />
                                                        <ErrorMessage name="yearsOfExperience" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fw-semibold">Languages Spoken:</label>
                                                        <Field name="languagesSpoken" type="text" className="form-control" placeholder="Languages Spoken" />
                                                        <ErrorMessage name="languagesSpoken" component="div" className="text-danger" />
                                                    </div>
                                                </div>

                                            </div>
                                            <button type="submit" className="btn btn-primary">Save Changes</button>
                                        </Form>
                                    )}
                                </Formik>
                                {feedback && (
                                    <div className={`alert alert-${feedback.type}`} role="alert">
                                        {feedback.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
