import api from '../../../helpers/api_helper';

const forgotPassword = async (emailData) => {
  try {
    const response = await api.post('/api/v1/users/forgotPassword', emailData);
    console.log('Password reset email sent successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending password reset email:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default forgotPassword;
