import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavbarDark from "../components/navbarDark";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import getUserProfile from "../common/realBackend/users/GetUserProfile"; // Adjust import path if needed
import axios from "axios";

export default function UserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile();
        setUserProfile(data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    fetchUserProfile();
  }, []);

  if (!userProfile) {
    return <p>Loading...</p>;
  }

  const {
    profileImageUrl,
    fullName,
    email,
    phoneNumber,
    address,
    acceptanceStatus,
    userSummaryInfo,
    subscriptionPlan
  } = userProfile.data;

  return (
    <>
      <NavbarDark />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="position-relative">
                <div className="candidate-cover">
                  <div className="profile-banner relative text-transparent">
                    <div className="relative shrink-0">
                      <img
                        src={profileImageUrl}
                        className="rounded shadow"
                        id="profile-banner"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="candidate-profile d-flex align-items-end mx-2">
                  <div className="position-relative">
                    <div className="position-relative d-inline-block">
                      <img
                        src={profileImageUrl}
                        className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                        id="profile-image"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="ms-2">
                    <h5 className="mb-0">{fullName}</h5>
                    <p className="text-muted mb-0">{userProfile.data.role}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rounded shadow p-4">
                <form>
                  <h5>Personal Details :</h5>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Full Name:</label>
                        <input
                          name="name"
                          id="fullname"
                          type="text"
                          className="form-control"
                          value={fullName}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Email:</label>
                        <input
                          name="email"
                          id="email2"
                          type="email"
                          className="form-control"
                          value={email}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Address:</label>
                        <input
                          name="address"
                          id="address"
                          type="text"
                          className="form-control"
                          value={address}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Mobile:</label>
                        <input
                          name="mobile"
                          id="mobile"
                          type="tel"
                          className="form-control"
                          value={phoneNumber}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">User Summary Info:</label>
                        <textarea
                          name="userSummaryInfo"
                          id="userSummaryInfo"
                          className="form-control"
                          value={userSummaryInfo}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Acceptance Status:</label>
                        <p className="form-control-plaintext">
                          {acceptanceStatus ? (
                            <span
                              className={`badge ${
                                acceptanceStatus === 'approved'
                                  ? 'bg-success'
                                  : acceptanceStatus === 'rejected'
                                  ? 'bg-danger'
                                  : 'bg-warning'
                              }`}
                            >
                              {acceptanceStatus.charAt(0).toUpperCase() + acceptanceStatus.slice(1)}
                            </span>
                          ) : (
                            <span className="badge bg-warning">Pending</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label fw-semibold">Subscription Plan:</label>
                        <div>
                          {subscriptionPlan ? (
                            <span className="badge bg-primary">{subscriptionPlan.name}</span>
                          ) : (
                            <span className="badge bg-danger">No Subscription Plan</span>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
