import api from '../../../helpers/api_helper';

const createJob = async (jobData) => {
  try {
    const response = await api.post('/api/v1/jobs', jobData);
    console.log('Job created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating job:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default createJob;
