// src/pages/reset-password.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import bg1 from '../assets/images/hero/bg6.jpg';
import logo from '../assets/images/colored logo.png';
import ModalComponent from '../pages/success-modals';
import resetPassword from '../common/realBackend/users/ResetPassword';

const validationSchema = Yup.object({
    token: Yup.string().required('Token is required'),
    newPassword: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters')
});

export default function ResetPassword() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');
    const [modalMessage, setModalMessage] = React.useState('');

    const formik = useFormik({
        initialValues: {
            token: '',
            newPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await resetPassword({ token: values.token, password: values.newPassword });
                setModalTitle('Success');
                setModalMessage('Password has been reset successfully.');
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    navigate('/login');
                }, 3000);
            } catch (error) {
                console.error('Error resetting password:', error.response ? error.response.data : error.message);
                setModalTitle('Error');
                setModalMessage('Something went wrong!');
                setShowModal(true);
            }
        }
    });

    return (
        <section className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="p-4 bg-white rounded shadow-md mx-auto w-100" style={{ maxWidth: '400px' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Link to="/">
                                    <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" style={{ width: '200px' }} />
                                </Link>
                                <h6 className="mb-2 text-uppercase fw-semibold">Enter new password</h6>

                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Token</label>
                                    <input
                                        name="token"
                                        id="token"
                                        type="text"
                                        className={`form-control ${formik.errors.token && formik.touched.token ? 'is-invalid' : ''}`}
                                        placeholder="Enter token"
                                        {...formik.getFieldProps('token')}
                                    />
                                    {formik.errors.token && formik.touched.token ? (
                                        <div className="invalid-feedback">{formik.errors.token}</div>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label fw-semibold">New Password</label>
                                    <input
                                        name="newPassword"
                                        id="newPassword"
                                        type="password"
                                        className={`form-control ${formik.errors.newPassword && formik.touched.newPassword ? 'is-invalid' : ''}`}
                                        placeholder="New Password"
                                        {...formik.getFieldProps('newPassword')}
                                    />
                                    {formik.errors.newPassword && formik.touched.newPassword ? (
                                        <div className="invalid-feedback">{formik.errors.newPassword}</div>
                                    ) : null}
                                </div>

                                <button className="btn btn-primary w-100" type="submit">Reset Password</button>
                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted small me-2">Remember your password? </span> <Link to="/login" className="text-dark fw-semibold small">Sign in</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent
                show={showModal}
                title={modalTitle}
                message={modalMessage}
            />
        </section>
    );
}
