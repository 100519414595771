import api from '../../../helpers/api_helper';

const resetPassword = async (resetData) => {
  try {
    const response = await api.patch('/api/v1/users/resetPassword', resetData);
    console.log('Password reset successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default resetPassword;