import api from '../../../helpers/api_helper';

const deleteCandidate = async (id) => {
  try {
    const response = await api.delete(`/api/v1/candidates/deleteCandidate/${id}`);
    console.log('Candidate deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting candidate:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default deleteCandidate;
