import company1 from "../assets/images/company/facebook-logo.png"
import company2 from "../assets/images/company/google-logo.png"
import company3 from "../assets/images/company/android.png"
import company4 from "../assets/images/company/lenovo-logo.png"
import company5 from "../assets/images/company/spotify.png"
import company6 from "../assets/images/company/linkedin.png"
import company7 from "../assets/images/company/circle-logo.png"
import company8 from "../assets/images/company/skype.png"
import company9 from "../assets/images/company/snapchat.png"
import company10 from "../assets/images/company/shree-logo.png"
import company11 from "../assets/images/company/telegram.png"
import company12 from "../assets/images/company/whatsapp.png"

import blog1 from "../assets/images/blog/01.jpg"
import blog2 from "../assets/images/blog/02.jpg"
import blog3 from "../assets/images/blog/03.jpg"
import blog4 from "../assets/images/blog/04.jpg"
import blog5 from "../assets/images/blog/05.jpg"
import blog6 from "../assets/images/blog/06.jpg"
import blog7 from "../assets/images/blog/07.jpg"
import blog8 from "../assets/images/blog/08.jpg"
import blog9 from "../assets/images/blog/09.jpg"

import work1 from "../assets/images/work/nanny2.jpg";
import work2 from "../assets/images/work/housekeeper.jpg";
import work3 from "../assets/images/work/driver1.jpg";
import work4 from "../assets/images/work/launderer1.jpg";
import work5 from "../assets/images/work/chef2.jpg";
import work6 from "../assets/images/work/cleaner.jpg";
import work7 from "../assets/images/work/butler1.jpg";
import work8 from "../assets/images/work/assistant1.jpg";
import work9 from "../assets/images/work/gardener.jpg";
import work10 from "../assets/images/work/security1.jpg";


import team1 from "../assets/images/team/01.jpg"
import team2 from "../assets/images/team/02.jpg"
import team3 from "../assets/images/team/03.jpg"
import team4 from "../assets/images/team/04.jpg"
import team5 from "../assets/images/team/05.jpg"
import team6 from "../assets/images/team/06.jpg"
import team7 from "../assets/images/team/07.jpg"
import team8 from "../assets/images/team/08.jpg"
import team9 from "../assets/images/team/09.jpg"
import team10 from "../assets/images/team/10.jpg"
import team11 from "../assets/images/team/11.jpg"
import team12 from "../assets/images/team/12.jpg"

import {FiAirplay,FiAward, FiAtSign,FiCodesandbox,FiChrome,FiCopy,FiCpu,FiPhone,FiActivity,FiClock,FiFileText, FiUsers, FiUser,FiInbox,FiThumbsUp,FiUmbrella, FiHelpCircle, FiBookmark, FiSettings, FiMail,FiMapPin} from "../assets/icons/vander"

export const categoriesData = [
    {
        icon:FiAirplay,
        title:'Business',
        title2:'Development',
        jobs:'74 Jobs'
    },
    {
        icon:FiAward,
        title:'Marketing &',
        title2:'Communication',
        jobs:'20 Jobs'
    },
    {
        icon:FiAtSign ,
        title:'Project',
        title2:'Management',
        jobs:'35 Jobs'
    },
    {
        icon:FiCodesandbox,
        title:'Customer',
        title2:'Service',
        jobs:'46 Jobs'
    },
    {
        icon:FiChrome,
        title:'Software',
        title2:'Engineering',
        jobs:'60 Jobs'
    },
    {
        icon:FiCopy ,
        title:'Human Resource',
        title2:'HR',
        jobs:'74 Jobs'
    },
    {
        icon:FiCpu,
        title:'It &',
        title2:'Networking',
        jobs:'20 Jobs'
    },
    {
        icon:FiInbox,
        title:'Sales &',
        title2:'Marketing',
        jobs:'35 Jobs'
    },
    {
        icon:FiThumbsUp,
        title:'Project',
        title2:'Manager',
        jobs:'46 Jobs'
    },
    {
        icon:FiUmbrella,
        title:'Data',
        title2:'Science',
        jobs:'60 Jobs'
    },
]

export const jobData = [
    {
        id:1,
        image:company1,
        name:'Sani Muhammad',
        title:'Driver',
        country:'Nigeria',
        city:'Lagos',    
        applied:'20',
        vacancy:'40',
        date:'13th Sep 2023',
        salary:'950 - 1100',
        posted:'2',
        jobTime:'Full Time',
        desc:'Looking for an experienced Driver for our company.'
    },
    {
        id:2,
        image:company2,
        name:'Samuel Janet',
        title:'Chef',
        country:'Abuja',
        city:'Moscow',    
        applied:'20',
        vacancy:'40',
        date:'29th Nov 2023',
        salary:'950 - 1100',
        posted:'5',
        jobTime:'Part Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:3,
        image:company3,
        name:'Sam james',
        title:'Cook',
        country:'Germany',
        city:'Berlin',    
        applied:'20',
        vacancy:'40',
        date:'29th Dec 2023',
        salary:'950 - 1100',
        posted:'3',
        jobTime:'Full Time',
        desc:'Looking for an experienced Cook for an our company.'
    },
    {
        id:4,
        image:company4,
        name:'Admin',
        title:'Laundrer',
        country:'Lagos',
        city:'Rome',    
        applied:'20',
        vacancy:'40',
        date:'13th March 2023',
        salary:'950 - 1100',
        posted:'2',
        jobTime:'Part Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:5,
        image:company5,
        name:'Mustapha Isaac',
        title:'Nanny',
        country:'Abuja',
        city:'Paris',    
        applied:'20',
        vacancy:'40',
        date:'5th May 2023',
        salary:'950 - 1100',
        posted:'3',
        jobTime:'Full Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:6,
        image:company6,
        name:'Sani John',
        title:'Security Personnel',
        country:'Abuja',
        city:'Athens',    
        applied:'20',
        vacancy:'40',
        date:'19th June 2023',
        salary:'950 - 1100',
        posted:'7',
        jobTime:'Remote',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:7,
        image:company7,
        name:'Adewale Joyce',
        title:'personal Assistant',
        country:'China',
        city:'Beijing',    
        applied:'20',
        vacancy:'40',
        date:'20th June 2023',
        salary:'950 - 1100',
        posted:'1',
        jobTime:'Full Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:8,
        image:company8,
        name:'Skype',
        title:'Marketing Director',
        country:'Japan',
        city:'Tokyo',    
        applied:'20',
        vacancy:'40',
        date:'31th Aug 2023',
        salary:'950 - 1100',
        posted:'2',
        jobTime:'Part Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:9,
        image:company9,
        name:'Snapchat',
        title:'Application Developer',
        country:'Singapore',
        city:'Singapore',    
        applied:'20',
        vacancy:'40',
        date:'1th Sep 2023',
        salary:'950 - 1100',
        posted:'4',
        jobTime:'Remote ',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:10,
        image:company10,
        name:'Shreethemes',
        title:'Senior Product Designer',
        country:'United States',
        city:'Washington',    
        applied:'20',
        vacancy:'40',
        date:'13th May 2023',
        salary:'950 - 1100',
        posted:'3',
        jobTime:'WFH',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:11,
        image:company11,
        name:'Telegram',
        title:'C++ Developer',
        country:'Spain',
        city:'Madrid',    
        applied:'20',
        vacancy:'40',
        date:'13th March 2023',
        salary:'950 - 1100',
        posted:'2',
        jobTime:'Full Time',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
    {
        id:12,
        image:company12,
        name:'Whatsapp',
        title:'Php Developer',
        country:'Jordan',
        city:'Amman',    
        applied:'20',
        vacancy:'40',
        date:'13th June 2023',
        salary:'950 - 1100',
        posted:'5',
        jobTime:'Remote',
        desc:'Looking for an experienced Web Designer for an our company.'
    },
]

export const servicesData = [
    {
        icon:FiCpu,
        title:'Promote Domestic Workers Visibility',
        desc:'Helping Households Discover Reliable Domestic Workers. Trusted by many to ensure the best match for your needs.'
    },
    {
        icon:FiActivity,
        title:'Domestic Workers Verified Before Platform Upload',
        desc:'Ensuring Quality and Trust with Every Listing. All domestic workers undergo thorough verification before being uploaded to our platform.'
    },
    {
        icon:FiClock,
        title:'Saves Time',
        desc:'Many households now use our platform to quickly find and hire domestic workers, saving valuable time.'
        
    },
    {
        icon: FiThumbsUp,
        title: 'Recommendations',
        desc: "Employers can leave recommendations for domestic workers, boosting their profiles with endorsements and comments that highlight their reliability and quality, increasing their hiring chances."

    },
    {
        icon: FiInbox,
        title: 'Blacklist',
        desc: "Employers can blacklist domestic workers following KYC verification and an admin review. Approved blacklists serve as warnings, decreasing the worker's chances of being hired. For severe offenses, the worker may be permanently blacklisted."
    },
    
]

export const blogData = [
    {
      id:1,
      image:blog1,
      title:'Precheck hire is gaining momentum and expanding',
      date:'13th July 2024',
      time:'5 min read',
      tag:'Jobs',
      company:'Admin'
    },
    {
        id:2,
        image:blog2,
        title:'Tackling job insecurity in our own way',
        date:'2 of June, 2024',
        time:'5 min read',
        tag:'Jobs',
        company:'Admin'
      },
      {
        id:3,
        image:blog3,
        title:'Precheck Hirekeeps strengthening the medium between artisans, skilled workers and employers',
        date:'29th Jun 2024',
        time:'5 min read',
        tag:'Jobs',
        company:'Admin'
      },
      {
        id:4,
        image:blog4,
        title:'11 Tips to Help You Get New Clients Through Cold Calling',
        date:'13th March 2023',
        time:'5 min read',
        tag:'Arts',
        company:'Google'
      },
      {
        id:5,
        image:blog5,
        title:'DigitalOcean launches first Canadian data centre in Toronto',
        date:'5th May 2023',
        time:'5 min read',
        tag:'Illustration',
        company:'Facebook'
      },
      {
        id:6,
        image:blog6,
        title:'Using Banner Stands To Increase Trade Show Traffic',
        date:'19th June 2023',
        time:'5 min read',
        tag:'Music',
        company:'Linkedin'
      },
      {
        id:7,
        image:blog7,
        title:'11 Tips to Help You Get New Clients Through Cold Calling',
        date:'20th June 2023',
        time:'5 min read',
        tag:'Arts',
        company:'Google'
      },
      {
        id:8,
        image:blog8,
        title:'DigitalOcean launches first Canadian data centre in Toronto',
        date:'31st Aug 2023',
        time:'5 min read',
        tag:'Illustration',
        company:'Facebook'
      },
      {
        id:9,
        image:blog9,
        title:'Using Banner Stands To Increase Trade Show Traffic',
        date:'1st Sep 2024',
        time:'5 min read',
        tag:'Music',
        company:'Linkedin'
      },
]

export const recentBlog = [
    {
        image: blog1,
        title: 'The Importance of Professional Domestic Workers',
        date: '13th March 2023'
    },
    {
        image: blog2,
        title: 'Enhancing Household Efficiency with Precheck Hire',
        date: '5th May 2023'
    },
    {
        image: blog1,
        title: 'Training and Development for Domestic Workers',
        date: '19th June 2023'
    },
];


export const servicesTwo = [
    {
        image: work1,
        title: 'Nanny',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work2,
        title: 'HouseKeeper',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work3,
        title: 'Driver',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work4,
        title: 'Launderer',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work5,
        title: 'Chef',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work6,
        title: 'Cleaner',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work7,
        title: 'Butler',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work8,
        title: 'Personal Assistant',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work9,
        title: 'Gardener',
        link: 'https://employer.precheckhire.com/'
    },
    {
        image: work10,
        title: 'Security Personnel',
        link: 'https://employer.precheckhire.com/'
    },
];



export const categoriesTwoData = [
    {
        title:'Senior Housekeeper',
        job:'90 Jobs Available'
    },
    {
        title:'Laundrer',
        job:'90 Jobs Available'
    },
    {
        title:'Cook',
        job:'90 Jobs Available'
    },
    {
        title:'Gardener',
        job:'90 Jobs Available'
    },
    {
        title:'Chef',
        job:'90 Jobs Available'
    },
    {
        title:'Nanny',
        job:'90 Jobs Available'
    },
    {
        title:'Driver',
        job:'90 Jobs Available'
    },
    {
        title:'Butler',
        job:'90 Jobs Available'
    },
    {
        title:'Security Personnel',
        job:'90 Jobs Available'
    },
    {
        title:'Personal Assistant',
        job:'90 Jobs Available'
    },
]

export const accordionData1 = [
    {
        title: 'How does it work?',
        desc: 'Learn how to upload profiles of your domestic workers and connect them with potential employers.'
    },
    {
        title: 'Is it free to use?',
        desc: 'Yes, our platform is free for employers to upload and manage profiles of domestic workers.'
    },
    {
        title: 'What information should I include?',
        desc: 'Include the domestic worker’s name, skills, experience, and a brief description of their work history.'
    },
    {
        title: 'How can I ensure the best matches?',
        desc: 'Provide detailed and accurate information about the domestic worker’s skills and experience to help employers make informed decisions.'
    },
];

export const accordionData2 = [
    {
        title: 'How to get started?',
        desc: 'Sign up for an account and start uploading profiles of domestic workers with detailed information about their skills and experience.'
    },
    {
        title: 'How do I create a profile?',
        desc: 'Go to the profile section, fill in the required details about the domestic worker, and click save.'
    },
    {
        title: 'How do employers contact me?',
        desc: 'Employers can contact you through our platform if they are interested in your domestic worker candidates.'
    },
];

export const accordionData3 = [
    {
        title: 'Boosting profile visibility',
        desc: 'Employers can subscribe to our premium service to enhance the visibility of their candidates’ profiles to potential employers.'
    },
    {
        title: 'Subscription plans',
        desc: 'We offer various subscription plans, including monthly and yearly options, to suit your needs. Check our pricing page for details.'
    },
    {
        title: 'Managing subscriptions',
        desc: 'You can manage your subscription, including upgrading or canceling, from your account settings under the subscription tab.'
    },
];

export const accordionData4 = [
    {
        title: 'Managing profiles',
        desc: 'You can delete and manage profiles of domestic workers from your account dashboard.'
    },
    {
        title: 'Resetting password',
        desc: 'If you need to reset your password, click on "Forgot Password" at the login page and follow the instructions.'
    },
    {
        title: 'Where to find support?',
        desc: 'Visit our support center for assistance, FAQs, and guidelines to ensure a smooth experience.'
    },
];



export const jobOpenings = [
    {
        title:'Frontend Developer',
        position:'Total Openings: 1'
    },
    {
        title:'Backend Developer',
        position:'Total Openings: 3'
    },
    {
        title:'Web Developer',
        position:'Total Openings: 2'
    },
]

export const candidatesData = [
    {
        id:1,
        image:team1,
        name:'Steven Townsend',
        post:'Marketing Director',
        salary:'$5k - $6k',
        experience:'2 Years',
        rate:true
    },
    {
        id:2,
        image:team2,
        name:'Tiffany Betancourt',
        post:'Application Developer',
        salary:'$4k - $5k',
        experience:'1 Years',
        rate:false
    },
    {
        id:3,
        image:team3,
        name:'Jacqueline Burns',
        post:'Senior Product Designer',
        salary:'$3k - $4k',
        experience:'1 Years',
        rate:false
    },
    {
        id:4,
        image:team4,
        name:'Mari Harrington',
        post:'C++ Developer',
        salary:'$7k - $8k',
        experience:'3 Years',
        rate:true
    },
    {
        id:5,
        image:team5,
        name:'Floyd Glasgow',
        post:'Php Developer',
        salary:'$3k - $4k',
        experience:'2 Years',
        rate:false
    },
    {
        id:6,
        image:team6,
        name:'Donna Schultz',
        post:'Web Designer / Developer',
        salary:'$6k - $7k',
        experience:'3 Years',
        rate:false
    },
    {
        id:7,
        image:team7,
        name:'Joshua Morris',
        post:'Marketing Director',
        salary:'$3k - $4k',
        experience:'1 Years',
        rate:false
    },
    {
        id:8,
        image:team8,
        name:'Rosaria Vargas',
        post:'Application Developer',
        salary:'$5k - $6k',
        experience:'2 Years',
        rate:true
    },
    {
        id:9,
        image:team9,
        name:'Steven Townsend',
        post:'Marketing Director',
        salary:'$5k - $6k',
        experience:'2 Years',
        rate:false
    },
    {
        id:10,
        image:team10,
        name:'Tiffany Betancourt',
        post:'Cleaner',
        salary:'N4k - N5k',
        experience:'1 Years',
        rate:true
    },
    {
        id:11,
        image:team11,
        name:'Jacqueline Burns',
        post:'Senior Product Designer',
        salary:'$3k - $4k',
        experience:'1 Years',
        rate:true
    },
    {
        id:12,
        image:team12,
        name:'Mari Harrington',
        post:'C++ Developer',
        salary:'$7k - $8k',
        experience:'3 Years',
        rate:false
    },
   
]
export const candidateSkill = [
    {
        title:'HTML',
        value:'84%'
    },
    {
        title:'CSS',
        value:'75%'
    },
    {
        title:'JQuery',
        value:'79%'
    },
    {
        title:'WordPress',
        value:'79%'
    },
    {
        title:'Figma',
        value:'85%'
    },
    {
        title:'Illustration',
        value:'65%'
    },
]

export const teamData = [
    {
        image:team1,
        name:'Jack John',
        title:'Job Seeker'
    },
    {
        image:team2,
        name:'Krista John',
        title:'Job Seeker'
    },
    {
        image:team3,
        name:'Roger Jackson',
        title:'Job Seeker'
    },
    {
        image:team4,
        name:'Johnny English',
        title:'Job Seeker'
    },
]
export const helpcenterData = [
    {
        icon:FiHelpCircle,
        title:'FAQs',
        desc:'Find answers to common questions about uploading and managing profiles of domestic workers for potential employers. '
    },
]

export const commentsData = [
    {
        image: team1,
        name: 'Lorenzo Peterson',
        date: '13th March 2023 at 1:30 pm',
        desc: '" The services provided by Precheck Hire have greatly improved the efficiency of my household. The domestic workers are professional and well-trained. "'
    },
    {
        image: team2,
        name: 'Tammy Camacho',
        date: '5th May 2023 at 10:00 am',
        desc: '" Precheck Hire offers exceptional domestic work services. The staff are trustworthy and hardworking, making my life much easier. "'
    },
    {
        image: team3,
        name: 'Samuel Adams',
        date: '19th June 2023 at 9:00 am',
        desc: '" I am very impressed with the level of service and professionalism from Precheck Hire. Their domestic workers are efficient and reliable. "'
    },
    {
        image: team4,
        name: 'Olivia Brown',
        date: '1st Sep 2023 at 1:30 pm',
        desc: '" Precheck Hire has been a game-changer for my household. The domestic workers are skilled and have a great work ethic. Highly recommended. "'
    },
];

export const contactData = [
    {
        icon:FiPhone,
        title:'Phone',
        desc:'Start working with Jobnova that can provide everything',
        link:'tel:+152534-468-854'
    },
    {
        icon:FiMail,
        title:'Email',
        desc:'Start working with Jobnova that can provide everything',
        link:'contact@example.com'
    },
    {
        icon:FiMapPin,
        title:'Location',
        desc:'Start working with Jobnova that can provide everything',
        link:'View on Google map'
    },
]