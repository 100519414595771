import api from '../../../helpers/api_helper';

const postReview = async (reviewData) => {
  try {
    const response = await api.post('/api/v1/candidates/postReview', reviewData);
    console.log('Review posted successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error posting review:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default postReview;
