import api from '../../../helpers/api_helper';

const emailTokenVerification = async (tokenData) => {
  try {
    const response = await api.post('/api/v1/users/emailTokenVerification', tokenData);
    console.log('Email token verification successful:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error during email token verification:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default emailTokenVerification;
