import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular, faStarHalfAlt } from '@fortawesome/free-regular-svg-icons';

export const getInitials = (name) => {
    if (!name) return '';
    const names = name.split(' ');
    return names.map(n => n[0].toUpperCase()).join('');
};

export const formatSalary = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    
    const number = parseInt(amount);

    if (number >= 1000) {
        return `₦${(number / 1000).toFixed(0)}K`; // Convert to thousands and add "K"
    } else {
        return `₦${number.toLocaleString()}`; // Format without "K"
    }
};

export const StarRating2 = ({ rating }) => {
    // Validate the rating to ensure it's within the expected range
    const validatedRating = Math.min(Math.max(rating, 0), 5);
    console.log('Validated Rating:', validatedRating);

    const fullStars = Math.floor(validatedRating); // Number of full stars
    console.log('Full Stars:', fullStars);

    const hasHalfStar = validatedRating % 1 >= 0.5; // Check if there's a half star
    console.log('Has Half Star:', hasHalfStar);

    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Number of empty stars
    console.log('Empty Stars:', emptyStars);

    return (
        <div className="text-warning mt-1" aria-label={`Rating: ${validatedRating} out of 5`}>
            {/* Render full stars */}
            {Array(fullStars).fill().map((_, index) => (
                <FontAwesomeIcon key={`full-${index}`} icon={faStarSolid} />
            ))}

            {/* Render half star if needed */}
            {hasHalfStar && <FontAwesomeIcon icon={faStarHalfAlt} />}

            {/* Render empty stars */}
            {Array(emptyStars).fill().map((_, index) => (
                <FontAwesomeIcon key={`empty-${index}`} icon={faStarRegular} />
            ))}
        </div>
    );
};