import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar'; // Adjust the import path as necessary
import bg1 from '../../assets/images/hero/bg.jpg'; // Adjust the path as necessary

export default function Header({ navClass, navLight }) {
    return (
        <>
            {/* Navbar */}
            <Navbar navClass={navClass} navLight={navLight} />

            {/* Header Section with Background Image and Breadcrumb */}
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                                    Create a Domestic Worker
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Precheck Hire</Link></li>
                                <li className="breadcrumb-item"><Link to="/job-grid-one">Candidates</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create a Domestic Worker</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        </>
    );
}
