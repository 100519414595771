import React, { useState, useRef } from 'react';
import Header from './header';
import WorkerForm from './worker-form';
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

export default function CreateDomesticWorker() {

    return (
        <>
            <Header />
            <WorkerForm/>
            <Footer top={true} />
            <ScrollTop />
        </>
    );
}
