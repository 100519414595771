import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bg1 from '../assets/images/hero/bg6.jpg';
import logo from '../assets/images/colored logo.png';
import emailTokenVerification from '../common/realBackend/users/EmailTokenVerification';

const EmailVerification = () => {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            token: '',
        },
        validationSchema: Yup.object({
            token: Yup.string().required('Verification token is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                await emailTokenVerification(values);
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            } catch (error) {
                setErrors({ submit: error.response?.data?.message || 'Something went wrong!' });
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <section className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="p-4 bg-white rounded shadow-md mx-auto w-100" style={{ maxWidth: '400px' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Link to="/">
                                    <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" style={{ width: '200px' }} />
                                </Link>
                                <h6 className="mb-2 text-uppercase fw-semibold">Verify your email</h6>
                                <p className="text-muted">Please enter the verification token sent to your email.</p>
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Verification Token</label>
                                    <input
                                        name="token"
                                        id="token"
                                        type="text"
                                        className={`form-control ${formik.errors.token ? 'is-invalid' : ''}`}
                                        placeholder="Enter verification token"
                                        value={formik.values.token}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.token && formik.touched.token ? (
                                        <div className="invalid-feedback">{formik.errors.token}</div>
                                    ) : null}
                                </div>
                                <button className="btn btn-primary w-100" type="submit" disabled={formik.isSubmitting}>
                                    {formik.isSubmitting ? 'Verifying...' : 'Verify Email'}
                                </button>
                                {formik.errors.submit && <div className="alert alert-danger mt-3">{formik.errors.submit}</div>}
                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted small me-2">Remember your password? </span> <Link to="/login" className="text-dark fw-semibold small">Sign in</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EmailVerification;
