import api from '../../../helpers/api_helper';

const getBlogById = async (id) => {
  try {
    const response = await api.get(`/api/v1/blogs/getBlogById/${id}`);
    console.log('Blog fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getBlogById;
