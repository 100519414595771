import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import bg1 from '../assets/images/hero/bg.jpg';
import heroImg from "../assets/images/nanny.jpg";
import Navbar from "../components/navbar";
import Counter from '../components/counter';
import AboutUs from '../components/aboutUs';
import Companies from '../components/companies';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { FiSearch, FiClock, FiMapPin, FiDollarSign } from "../assets/icons/vander";
import ModalVideo from 'react-modal-video';
import { categoriesTwoData, jobData } from '../data/data';

import ServicesTwo from "../components/sercicesTwo";

// Import Framer Motion
import { motion } from 'framer-motion';

export default function IndexThree() {
    
    const [currentText, setCurrentText] = useState(0);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentText(prevText => (prevText === 0 ? 1 : 0));
        }, 10000); // Change text every 10 seconds

        return () => clearInterval(interval);
    }, []);

    // Define slideVariants here to ensure it is in scope
    const slideVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 1 }
        },
        exit: { opacity: 0, x: 100, transition: { duration: 1 } }
    };

    const textContent = [
        {
            heading: "Hire Trustworthy Domestic Workers",
            description: "Precheck Hire connects you with the right person for your home. We make it easy to find reliable and skilled workers who meet your needs, whether for cleaning, cooking, childcare, or other household tasks. Trust us to help you find dependable support for your home."
        },
        {
            heading: "Find Jobs for Domestic Workers",
            description: "With Precheck Hire, you can connect with families that need your help as a domestic worker. Whether it’s cleaning, cooking, or caregiving, get work that matches what you know."
        }
    ];

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-260 pb-lg-0 pb-md-4 bg-primary d-table w-100" style={{ backgroundImage: `url(${bg1})` }} id="home">
                <div className="bg-overlay bg-black" style={{ opacity: '0.8' }}></div>
                <div className="container">
                    <div className="row g-4 position-relative z-1">
                        <div className="col-lg-7 col-md-6 col-12 mt-lg-5">
                            <motion.div
                                key={currentText}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={slideVariants}
                                className="title-heading"
                            >
                                <h1 className="heading text-white title-dark mb-4">{textContent[currentText].heading}</h1>
                                <p className="para-desc text-white-50">{textContent[currentText].description}</p>
                            </motion.div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="bg-white rounded shadow p-2 position-relative">
                                <img src={heroImg} className="img-fluid rounded" alt="" />

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <Counter />

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center mb-4 pb-2">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h4 className="title mb-3">Browse For Domestic Workers To Hire</h4>
                                <p className="text-muted para-desc mx-auto mb-0">
                                    Discover trusted domestic workers skilled in household tasks. Whether you're looking for assistance with cleaning, childcare, cooking, or maintenance, our platform connects you with professionals ready to make a difference in your home.
                                </p>
                            </div>
                        </div>
                    </div>

                    <ServicesTwo/>
                </div>

                <div className="container mt-100 mt-60">
                    <Companies />
                </div>


                <AboutUs containerClass="container mt-100 mt-60"/>

                <style jsx="true">{`
                    @media (max-width: 768px) {
                        .heading {
                            font-size: 1.8rem;
                        }
                        .btn-custom {
                            padding: 10px 20px;
                            font-size: 14px;
                        }
                        .d-flex {
                            flex-direction: column;
                            gap: 10px;
                        }
                    }
                `}</style>
            </section>
            <Footer />
            <ScrollTop />
        </>
    );
}
