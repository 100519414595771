import api from '../../../helpers/api_helper';

const getUserJobs = async () => {
  try {
    const response = await api.get('/api/v1/jobs/user/jobs');
    console.log('User jobs fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user jobs:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default getUserJobs;
