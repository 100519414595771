import api from '../../../helpers/api_helper';

const updateCandidate = async (id, candidateData) => {
  try {
    const formData = new FormData();
    
    // Append each field to the FormData
    for (const key in candidateData) {
      if (Array.isArray(candidateData[key])) {
        // Append arrays as JSON string if that is what the API expects
        formData.append(key, JSON.stringify(candidateData[key]));
      } else {
        // Append other fields directly
        formData.append(key, candidateData[key]);
      }
    }

    const response = await api.patch(`/api/v1/candidates/updateCandidate/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    console.log('Candidate updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating candidate:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default updateCandidate;
