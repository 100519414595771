import React, { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import bg1 from '../assets/images/hero/bg6.jpg';
import logo from '../assets/images/colored logo.png';
import forgotPassword from '../common/realBackend/users/ForgotPassword';
import ModalComponent from '../pages/success-modals';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState('');
    const [modalMessage, setModalMessage] = React.useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                await forgotPassword(values);
                setModalTitle('Success');
                setModalMessage('Password reset link has been sent to your email.');
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                    navigate('/reset-password'); // Navigate to ResetPassword page
                }, 3000);
            } catch (error) {
                console.error('Error sending password reset email:', error);
                setModalTitle('Error');
                setModalMessage(error.response?.data?.message || 'Something went wrong!');
                setShowModal(true);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <section className="bg-home d-flex align-items-center" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="p-4 bg-white rounded shadow-md mx-auto w-100" style={{ maxWidth: '400px' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Link to="/">
                                    <img src={logo} className="mb-4 d-block mx-auto" alt="Logo" style={{ width: '200px' }} />
                                </Link>
                                <h6 className="mb-2 text-uppercase fw-semibold">Reset your password</h6>
                                <p className="text-muted">Please enter your email address. You will receive a link to create a new password via email.</p>
                                <div className="mb-3">
                                    <label className="form-label fw-semibold">Your Email</label>
                                    <input
                                        name="email"
                                        id="email"
                                        type="email"
                                        className={`form-control ${formik.errors.email ? 'is-invalid' : ''}`}
                                        placeholder="example@gmail.com"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.errors.email && formik.touched.email ? (
                                        <div className="invalid-feedback">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <button className="btn btn-primary w-100" type="submit" disabled={formik.isSubmitting}>
                                    {formik.isSubmitting ? 'Sending...' : 'Send'}
                                </button>
                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted small me-2">Remember your password? </span> <Link to="/login" className="text-dark fw-semibold small">Sign in</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent
                show={showModal}
                title={modalTitle}
                message={modalMessage}
            />
        </section>
    );
}
