import {FiPhone, FiMail,FiMapPin} from "../assets/icons/vander"

export const contactData = [
    {
        icon:FiPhone,
        title:'Phone',
        desc:'Get started with PreCheck Hire, the platform where domestic workers can easily upload their profiles and find employment.',
        link:'tel:+234-123-456-7890'
    },
    {
        icon:FiMail,
        title:'Email',
        desc:'Get started with PreCheck Hire, the platform where domestic workers can easily upload their profiles and find employment.',
        link:'support@precheckhire.com'
    },
]