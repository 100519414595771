import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import createCandidate from '../../common/realBackend/candidates/CreateCandidate';
import getAllDomesticCategories from '../../common/realBackend/domesticCategory/GetAllDomesticCategories';
import getNigeriaLGAbyStateName from '../../common/realBackend/users/GetNigeriaLGAbyStateName';
import getNigeriaState from '../../common/realBackend/users/GetNigeriaState';

const splitName = (fullName) => {
    const nameParts = fullName.trim().split(' ');

    if (nameParts.length === 1) {
        return { firstName: nameParts[0], middleName: '', lastName: '' };
    } else if (nameParts.length === 2) {
        return { firstName: nameParts[0], middleName: '', lastName: nameParts[1] };
    }

    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    const middleName = nameParts.slice(1, -1).join(' ');

    return { firstName, middleName, lastName };
};

const WorkerForm = () => {
    const [areaCodes, setAreaCodes] = useState([]);
    const [selectedCode, setSelectedCode] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [exactMatchMessage, setExactMatchMessage] = useState('');
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const searchInputRef = useRef(null);
    const resultsListRef = useRef(null);

    useEffect(() => {
        const fetchAreaCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const data = response.data.map(country => ({
                    code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
                    flag: country.flags.svg,
                    name: country.name.common
                }));
                const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
                setAreaCodes(sortedData);
            } catch (error) {
                console.error('Error fetching area codes:', error);
            }
        };
        fetchAreaCodes();
    }, []);
    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getAllDomesticCategories();
                console.log("API Response:", response); // Debugging line

                if (Array.isArray(response)) {
                    setCategories(response);
                    console.log('Categories state set:', response);
                } else {
                    console.warn("No categories found in response"); // Debugging line
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        const fetchStates = async () => {
            try {
                const response = await getNigeriaState();
                console.log("API Response State:", response); // Debugging line
                if (Array.isArray(response.data)) {
                    setStates(response.data);
                    console.log("States set:", response.data);
                } else {
                    console.warn("No states found in response");
                }
            } catch (error) {
                console.error('Error fetching States:', error);
            }
        };

        fetchCategories();
        fetchStates();
    }, []);

    useEffect(() => {
        const fetchLgas = async () => {
            if (selectedState) {
                try {
                    console.log('Fetching LGAs for state:', selectedState);
                    const response = await getNigeriaLGAbyStateName(selectedState);
                    console.log('API Response:', response);

                    if (Array.isArray(response.data.lgas)) {
                        setLgas(response.data.lgas);
                    } else {
                        console.warn("No LGAs found in response or incorrect structure");
                    }
                } catch (error) {
                    console.error('Error fetching LGAs:', error);
                }
            } else {
                setLgas([]); // Clear LGAs if no state is selected
            }
        };

        fetchLgas();
    }, [selectedState]);
    

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        // Implement your search logic here
    };

    const handleAreaCodeChange = (event) => {
        setSelectedCode(event.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // Implement your search logic here
        }
    };

    const handleSelectName = (name) => {
        setSearchValue(name);
        setSearchResults([]);
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required')
            .test('is-full-name', 'Please enter at least a first and last name', (value) => {
                const nameParts = value.trim().split(' ');
                return nameParts.length >= 2;
            }),
        about: Yup.string().required('About Domestic Worker is required'),
        category: Yup.string().required('Job Category is required'),
        // salaryMin: Yup.number().required('Minimum Salary is required').positive().integer(),
        // salaryMax: Yup.number().required('Maximum Salary is required').positive().integer(),
        state: Yup.string().required('State is required'),
        lga: Yup.string().required('LGA is required'),
        phoneNumber: Yup.string().required('Phone Number is required'),
        // email: Yup.string().email('Invalid email address').required('Email Address is required'),
        // highestDegree: Yup.string().required('Highest Degree Obtained is required'),
        // institution: Yup.string().required('Institution is required'),
        // graduationYear: Yup.number().required('Graduation Year is required').positive().integer(),
        yearsOfExperience: Yup.number().required('Years of Experience is required').positive().integer(),
        languages: Yup.string().required('Languages are required'),
    });

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setIsSubmitting(true);
    
        const form = event.target;
        const formData = new FormData(form);
    
        try {
            // Log all form data for debugging
            console.log('FormData values:', Array.from(formData.entries()));
    
            // Extracting name components
            const fullName = formData.get('name') || '';
            const { firstName, middleName, lastName } = splitName(fullName);
            console.log('Split name:', { firstName, middleName, lastName });
    
            // Prepare candidate data
            const candidateData = new FormData();
            candidateData.append('firstName', firstName);
            candidateData.append('middleName', middleName);
            candidateData.append('lastName', lastName);
            candidateData.append('about', formData.get('about') || '');
            candidateData.append('phone', `${formData.get('areaCode') || ''}${formData.get('phoneNumber') || ''}`);
            candidateData.append('whatsapp', `${formData.get('areaCode') || ''}${formData.get('whatsapp') || ''}`);
            candidateData.append('yearsOfExperience', formData.get('yearsOfExperience') || '');
            candidateData.append('location', `${formData.get('state') || ''}, ${formData.get('lga') || ''}`);
            candidateData.append('salaryRangeMin', formData.get('salaryMin') || '');
            candidateData.append('salaryRangeMax', formData.get('salaryMax') || '');
    
            // Handle education
            candidateData.append('education', JSON.stringify([
                {
                    institution: formData.get('institution') || '',
                    degree: formData.get('highestDegree') || 'No degree specified',
                    endYear: formData.get('graduationYear') || '',
                }
            ]));
    
            // Handle languages
            const languages = formData.get('languages');
            if (languages) {
                const languagesArray = languages.split(',').map(lang => lang.trim());
                candidateData.append('languagesSpoken', JSON.stringify(languagesArray)); // Append as JSON array
            }
    
            // Handle category ID
            const categoryId = formData.get('category');
            if (categoryId) {
                const categoryArray = JSON.stringify([parseInt(categoryId, 10)]);
                candidateData.append('domesticCategoryIds', categoryArray); // Send as a JSON array
            }
    
            // Handle file uploads
            const profilePicture = formData.get('profilePicture');
            if (profilePicture && profilePicture instanceof File) {
                candidateData.append('profileImage', profilePicture);
                console.log('Profile picture:', profilePicture);
            }
    
            const coverImage = formData.get('coverImage');
            if (coverImage && coverImage instanceof File) {
                candidateData.append('backgroundImage', coverImage);
                console.log('Cover image:', coverImage);
            }
    
            const cv = formData.get('cv');
            if (cv && cv instanceof File) {
                candidateData.append('resume', cv);
                console.log('CV:', cv);
            }
    
            // Log FormData entries
            candidateData.forEach((value, key) => {
                console.log(`FormData Key: ${key}, Value: ${value}`);
            });
    
            // Make the API request
            const response = await createCandidate(candidateData);
            console.log('Response from API:', response);
    
            if (response.status === 'success') {
                setSuccessMessage('Candidate created successfully!');
                setErrorMessage('');
            } else {
                setErrorMessage('Error creating candidate. Please try again.');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('Error creating candidate. Please try again.');
            setSuccessMessage('');
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <section className="section bg-light">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8">
                        <div className="card border-0">
                            <Formik
                                initialValues={{
                                    name: '',
                                    about: '',
                                    category: '',
                                    salaryMin: '',
                                    salaryMax: '',
                                    state: '',
                                    lga: '',
                                    phoneNumber: '',
                                    whatsapp: '',
                                    email: '',
                                    highestDegree: '',
                                    fieldOfStudy: '',
                                    institution: '',
                                    graduationYear: '',
                                    yearsOfExperience: '',
                                    languages: '',
                                    profilePicture: null,
                                    coverImage: null,
                                    cv: null,
                                
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form className="rounded shadow p-4" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <h5 className="mb-3">Profile Overview:</h5>
                                            <div className="alert alert-danger mt-2">
                                                <div className="d-flex align-items-start">
                                                    <i className="fa fa-exclamation-triangle fa-fw me-2"></i>
                                                    <div>
                                                        <p className="mb-3">
                                                            When you add a worker's information to Precheck Hire, it becomes ours to use for running and improving our service.
                                                        </p>
                                                        <p className="mb-0">
                                                            Precheck Hire isn't involved in what happens between you and a domestic worker you find here. We connect you, but the rest is up to you both.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="profilePicture" className="form-label fw-semibold">Profile Picture (Compulsory) :</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        id="profilePicture"
                                                        name="profilePicture"
                                                        onChange={(e) => setFieldValue('profilePicture', e.currentTarget.files[0])}
                                                        required
                                                    />
                                                    {values.profilePicture && (
                                                        <div className="mt-2">
                                                            <img src={URL.createObjectURL(values.profilePicture)} alt="Profile Preview" className="img-thumbnail" style={{ maxWidth: '150px' }} />
                                                        </div>
                                                    )}
                                                    <ErrorMessage name="profilePicture" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="coverImage" className="form-label fw-semibold">Profile Cover Image :</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        id="coverImage"
                                                        name="coverImage"
                                                        onChange={(e) => setFieldValue('coverImage', e.currentTarget.files[0])}
                                                    />
                                                    {values.coverImage && (
                                                        <div className="mt-2">
                                                            <img src={URL.createObjectURL(values.coverImage)} alt="Cover Image Preview" className="img-thumbnail" style={{ maxWidth: '150px' }} />
                                                        </div>
                                                    )}
                                                    <ErrorMessage name="coverImage" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ position: 'relative' }}>
                                                <div className="mb-3" style={{ position: 'relative' }}>
                                                    <label className="form-label fw-semibold">Full Name :</label>
                                                    <Field
                                                        placeholder="Firstname Middlename Lastname"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={(e) => {
                                                            handleInputChange(e);
                                                            setFieldValue('name', e.target.value);
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                        value={searchValue}
                                                        autoComplete="off"
                                                    />

                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                    {searchResults.length > 0 && (
                                                        <ul ref={resultsListRef} className="list-group" style={{
                                                            position: 'absolute',
                                                            zIndex: 1000,
                                                            width: '100%',
                                                            maxHeight: '150px',
                                                            overflowY: 'auto',
                                                            backgroundColor: 'white',
                                                            border: '1px solid #ccc',
                                                            borderTop: 'none',
                                                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                                                            marginTop: '1px'
                                                        }}>
                                                            {searchResults.map((name, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="list-group-item"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleSelectName(name)}
                                                                >
                                                                    {name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {exactMatchMessage && (
                                                        <div className="alert alert-danger mt-2">
                                                            {exactMatchMessage}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">About Domestic Worker :</label>
                                                    <Field
                                                        as="textarea"
                                                        name="about"
                                                        id="about"
                                                        rows="4"
                                                        className="form-control"
                                                        placeholder="Tell us something about the worker "
                                                        required
                                                    />
                                                    <ErrorMessage name="about" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label fw-semibold">Job Category:</label>
                                                    <Field as="select" name="category" className="form-select" required>
                                                    <option value="">Select Category</option>
                                                    {categories.map(category => (
                                                        <option key={category.id} value={category.id}>{category.name}</option>
                                                    ))}
                                                    </Field>
                                                    <ErrorMessage name="category" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="cv" className="form-label fw-semibold">CV :</label>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        id="cv"
                                                        name="cv"
                                                        onChange={(e) => setFieldValue('cv', e.currentTarget.files[0])}
                                                    />
                                                    <ErrorMessage name="cv" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="mb-0">
                                                    <label className="form-label fw-semibold">Salary:</label>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="col-md-5">
                                                        <div className="mb-3">
                                                            <label className="form-label small fw-bold d-none"></label>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text">From</span>
                                                                <Field
                                                                    name="salaryMin"
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Minimum Salary"
                                                                />
                                                                <ErrorMessage name="salaryMin" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="mb-3">
                                                            <label className="form-label small fw-bold d-none"></label>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text">To</span>
                                                                <Field
                                                                    name="salaryMax"
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Maximum Salary"
                                                                    
                                                                />
                                                                <ErrorMessage name="salaryMax" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h5 className="mb-3">Contact:</h5>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="state" className="form-label fw-semibold">State:</label>
                                                    <Field as="select" name="state" className="form-select" required
                                                    onChange={(e) => {
                                                        const stateName = e.target.value;
                                                        setFieldValue("state", stateName);
                                                        setSelectedState(stateName);
                                                    }}>
                                                        <option value="">Select State</option>
                                                        {states.map(state => (
                                                            <option key={state} value={state}>{state}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="state" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="lga" className="form-label fw-semibold">LGA:</label>
                                                    <Field as="select" name="lga" className="form-select" required>
                                                    {lgas.map((lga, index) => (
                                                        <option key={index} value={lga}>{lga}</option>
                                                    ))}
                                                    </Field>
                                                    <ErrorMessage name="lga" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="phoneNumber" className="form-label fw-semibold">Phone Number:</label>
                                                    <div className="d-flex">
                                                    <select
                                                        name="areaCode"
                                                        id="areaCode"
                                                        className="form-control me-2"
                                                        onChange={handleAreaCodeChange}
                                                        required
                                                    >
                                                        <option value="">Select Country</option>
                                                        {areaCodes.map((areaCode, index) => (
                                                            <option key={index} value={areaCode.code}>
                                                                <img
                                                                    src={areaCode.flag}
                                                                    alt={areaCode.name}
                                                                    style={{ width: '20px', marginRight: '10px' }}
                                                                />
                                                                {areaCode.name} {areaCode.code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <Field
                                                        name="phoneNumber"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Phone Number"
                                                        required
                                                    />
                                                    <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="whatsapp" className="form-label fw-semibold">Whatsapp Number:</label>
                                                    <div className="d-flex">
                                                    <select
                                                        name="areaCode"
                                                        id="areaCode"
                                                        className="form-control me-2"
                                                        onChange={handleAreaCodeChange}
                                                        required
                                                    >
                                                        <option value="">Select Country</option>
                                                        {areaCodes.map((areaCode, index) => (
                                                            <option key={index} value={areaCode.code}>
                                                                <img
                                                                    src={areaCode.flag}
                                                                    alt={areaCode.name}
                                                                    style={{ width: '20px', marginRight: '10px' }}
                                                                />
                                                                {areaCode.name} {areaCode.code}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <Field
                                                        name="whatsapp"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Whatsapp Number"
                                                    />
                                                    <ErrorMessage name="whatsapp" component="div" className="text-danger" />
                                                </div>
                                                </div>
                                            </div>

                                            <h5 className="mb-3">Education:</h5>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="highestDegree" className="form-label fw-semibold">Highest Degree Obtained:</label>
                                                    <Field
                                                        name="highestDegree"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Highest Degree"
                                                        
                                                    />
                                                    <ErrorMessage name="highestDegree" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="institution" className="form-label fw-semibold">Institution:</label>
                                                    <Field
                                                        name="institution"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Institution"
                                                        
                                                    />
                                                    <ErrorMessage name="institution" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="graduationYear" className="form-label fw-semibold">Graduation Year:</label>
                                                    <Field
                                                        name="graduationYear"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Year of Graduation"
                                                        
                                                    />
                                                    <ErrorMessage name="graduationYear" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <h5 className="mb-3">Experience:</h5>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="yearsOfExperience" className="form-label fw-semibold">Years of Experience:</label>
                                                    <Field
                                                        name="yearsOfExperience"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Years of Experience"
                                                        required
                                                    />
                                                    <ErrorMessage name="yearsOfExperience" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="languages" className="form-label fw-semibold">Languages:</label>
                                                    <Field
                                                        name="languages"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Languages Spoken"
                                                        required
                                                    />
                                                    <ErrorMessage name="languages" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                                </button>
                                            </div>  
                                        </div>
                                    </Form>
                                    
                                )}
                            </Formik>
                            {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
                             {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkerForm;
